import "./itens.css";
import * as React from "react";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateFileUrl } from "../../requestMethods";
import { formatPricing } from "../../util/helper";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useCompany } from "../../components/context/CompanyContext";

export default function Itens() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const orderId = location.pathname.split("/")[3];
  const [data, setData] = useState();
  const [st, setStatus] = useState();
  const {companyId} = useCompany();

  useEffect(() => {
    if(companyId > 0){
    getOrders();
    }
  }, [companyId]);

  const getOrders = async () => {
    try {

      const res = await userRequest.get(`${companyId}/orders/${orderId}`);

     
      setData(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="list">
      <table className="custon_table">
        {data ? (
          <>
            {data.products.map((product) => (
              <tr className="bordered">
                <td>
                  <img
                    className="custom-item-img"
                    src={generateFileUrl(product.img)} />
                </td>
                <td style={{minWidth:"200px"}}>

                      <h4 className="custom_product_title">{product.title}</h4>
                      <h5 className="custom_cart_complement">
                        Vlr. unitário: R${product.price}
                      </h5>
                      <h5 className="custom_cart_complement">
                        Número de pcs:{product.quantity}
                      </h5>
                      <h5 className="custom_cart_complement">
                        Ref.:{product.ref}
                      </h5>
                      <h4 className="custom_product_price">
                        Preço:
                        {formatPricing(product.price * product.quantity)}
                      </h4>
                </td>
                <td>
                  <table className="custom-minigrid">
                    <thead>
                      <tr>
                        <td>&nbsp;</td>

                        {product.sizes.map((size) => (
                          <td key={size.ref}>{size.label}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {product.colors.map((color, iColor) => (
                        <tr key={color.ref}>
                          <td bgcolor={color.rgb}></td>
                          {product.sizes.map((size, iSize) => (
                            <td
                              key={size.ref}
                              className={!product.colorsSizesGrid[iColor][iSize]
                                ? "custom-minigrid-no-value"
                                : undefined}
                            >
                              {product.colorsSizesGrid[iColor][iSize] ===
                                undefined ||
                                product.colorsSizesGrid[iColor][iSize] ===
                                null ? (
                                <DoDisturbIcon />
                              ) : (
                                product.colorsSizesGrid[iColor][iSize] || 0
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </>
        ) : (
          false
        )}
      </table>
    </div>
  );
}
