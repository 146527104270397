import "../../global.css";
import "../product/product.css";
import "../product/importProducts.css";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useEffect, CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import { generateFileUrl } from "../../requestMethods";

export default function ImportCustomer() {
  const [dataXLS, setDataXLS] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();

  useEffect(() => {
    if (companyId > 0) {
      getManager();
    }
  }, [companyId]);

  useEffect(() => {}, [dataXLS]);

  const columns = [
    {
      field: "name",
      align: "left",
      headerName: "Nome",
      width: 200,
      headerClassName:'dataGridMuiHeader',
    },
    { field: "cnpj_cpf", headerName: "CNPJ", headerClassName:'dataGridMuiHeader',width: 200, align: "left" },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      align: "left",
      headerClassName:'dataGridMuiHeader',
    },
    { field: "contact", headerName: "Celular", headerClassName:'dataGridMuiHeader',width: 200, align: "left" },
    {
      field: "email",
      headerName: "Email",
      width: 160,
      align: "left",
      headerClassName:'dataGridMuiHeader',
    },
    { field: "street", headerName: "Endereço",headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    {
      field: "complement",
      headerName: "Numero e Complemento",
      width: 200,
      align: "left",
      headerClassName:'dataGridMuiHeader',
    },
    { field: "district", headerName: "Bairro", headerClassName:'dataGridMuiHeader',width: 200, align: "left" },
    { field: "city", headerName: "Cidade",headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    { field: "cep", headerName: "CEP", headerClassName:'dataGridMuiHeader',width: 200, align: "left" },
    { field: "state", headerName: "Estado",headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    { field: "managed", headerName: "Vendedor(a)",headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
  ];

  const getManager = async () => {
    const res = await userRequest.get(`${companyId}/users`);

    setUsers(res.data);
  };
  const _findManaged = (managed) => {
    const usr = users.find(
      (item) => item.name.toUpperCase() === managed.toUpperCase()
    );
    if (usr) {
      return usr.userId;
    }
    return null;
  };
  const _replacetel = (contact) => {
    if (contact !== "") {
      return contact
        .toString()
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(".", "");
    }
    return contact;
  };
  const addXLS = async () => {
    setIsLoading(true);
    if (dataXLS.length > 0) {
      const customer = Array.from(dataXLS).map((item) => ({
        name: item.name,
        cnpj_cpf: item.cnpj_cpf,
        rzSocial: item.rzSocial,
        contact: _replacetel(item.contact),
        email: item.email,
        password: _replacetel(item.contact).substring(2, 7),
        managedBy: _findManaged(item.managed),
        enabled: "Aprovado",
        address: {
          street: item.street,
          complement: item.complement,
          cep: item.cep,
          state: item.state,
          district: item.district,
          city: item.city,
        },
      }));

      const res = await userRequest.post(
        `${companyId}/customers/registerCustomer`,
        customer
      );

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Cliente inserido com sucesso!", "success");
      } else {
        Swal.fire("Atenção!", "Erro ao inserir cliente!", "error");
      }
    } else {
      Swal.fire("Atenção!", "Favor selecionar um aquivo!", "warning");
    }
    setIsLoading(false);
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      if (validaFile(workbook)) {
        const index = workbook.SheetNames.findIndex(
          (item) => item === "Clientes"
        );
        const sheetName = workbook.SheetNames[index];
        const sheet = workbook.Sheets[sheetName];

        sheet["A1"].w = "name";
        sheet["B1"].w = "cnpj_cpf";
        sheet["C1"].w = "rzSocial";
        sheet["D1"].w = "contact";
        sheet["E1"].w = "email";
        sheet["F1"].w = "street";
        sheet["G1"].w = "complement";
        sheet["H1"].w = "cep";
        sheet["I1"].w = "state";
        sheet["J1"].w = "city";
        sheet["K1"].w = "district";
        sheet["L1"].w = "managed";

        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setDataXLS(parsedData);
      } else {
        Swal.fire("Erro!", "Estrutura do arquivo modificada.", "warning");
      }
    };
  };

  const validaFile = (workbook) => {
    const index = workbook.SheetNames.findIndex((item) => item === "Clientes");
    const sheetName = workbook.SheetNames[index];
    const sheet = workbook.Sheets[sheetName];

    if (index === -1) {
      return false;
    }
    if (sheet["A1"].v !== "Nome") {
      return false;
    }
    if (sheet["B1"].v !== "CNPJ") {
      return false;
    }
    if (sheet["C1"].v !== "RzSocial") {
      return false;
    }
    if (sheet["D1"].v !== "Celular") {
      return false;
    }
    if (sheet["E1"].v !== "Email") {
      return false;
    }
    if (sheet["F1"].v !== "Endereço") {
      return false;
    }
    if (sheet["G1"].v !== "Numero e Complemento") {
      return false;
    }
    if (sheet["H1"].v !== "CEP") {
      return false;
    }
    if (sheet["I1"].v !== "Sigla Estado") {
      return false;
    }
    if (sheet["J1"].v !== "Cidade") {
      return false;
    }
    if (sheet["K1"].v !== "Bairro") {
      return false;
    }
    return true;
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  let downalodFIle = generateFileUrl("ImportFiles/ImportarCliente.xlsx");
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div>
              <input
                id="selecao-arquivo"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </div>
            <div>
              <button
                size="small"
                onClick={addXLS}
                className="button_custom_action_add_top"
              >
                Importar Excel
              </button>
            </div>
            <div>
              <labe>Informações para importação de clientes</labe>
              <br></br>
            </div>
            <div className="custom_label">
              <labe>
                1. O arquivo deve ser utilizado na mesma estrutura de download.
                <a href={downalodFIle} download>
                  Download Arquivo
                </a>
              </labe>
            </div>
            <div className="custom_label">
              <labe>
                2. Os campos de CNPJ e Celular devem ser apenas númenros
              </labe>
            </div>
            <div>
              <br></br>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily:"Manrope",
                  fontWeight:"500",
                  border:"0px"
                }}
                rowHeight={30} 
                localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                getRowId={(row) => row.cnpj_cpf + row.contact}
                rows={dataXLS}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
