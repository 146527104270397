
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc); dayjs.extend(timezone);
export function formatPricing(value){
    if (value) {
        const formattedValue = value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
        return formattedValue;
    }
}


export function formatPricingWithOutRS(value){
    if (value) {
        const formattedValue = new Intl.NumberFormat('pt-BR',  {minimumFractionDigits: 2}).format(value);
        return formattedValue;
    }
}

export function formatPricingDatabase(value){
    if (value) {
        const formattedValue = Number(value.replace("R$","").replace(",","."));
        return formattedValue;
    }
}

export const formatTime24hourFormat = (value) => {
    if (value) {
        const formattedValue = dayjs(value).tz('America/Sao_Paulo').format("DD/MM/YYYY HH:mm:ss");
        return formattedValue;
    }
}

export const formatTimeDDMMYYY= (value) => {
    if (value) {
        const formattedValue = dayjs(value).tz('America/Sao_Paulo').format("DD/MM/YYYY");
        return formattedValue;
    }
}
export const formatTimeYYYYMMDD= (value) => {
    if (value) {
        const formattedValue = dayjs(value).tz('America/Sao_Paulo').format("YYYY-MM-DD");
        return formattedValue;
    }
}

