import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import {CompanyContextProvider} from "./components/context/CompanyContext"
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('be1d90829acc7b6e354b52730fa20b3dTz04NzQyOCxFPTE3NDMyODAwOTMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={"null"} persistor={persistor}>
      <CompanyContextProvider>
        <App />
        </CompanyContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
