import "../../global.css"
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import { DataGridPremium,GridToolbarContainer,GridToolbarFilterButton,
  GridToolbarExport,GridToolbarColumnsButton } from "@mui/x-data-grid-premium";
import * as React from "react";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import { formatTimeDDMMYYY } from "../../util/helper";
import Loading from "../../components/loading/loading"
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";

export default function UserList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const {companyId} = useCompany();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {

  }, [data]);

  const getUsers = async () => {
    try {
      let url = "";

      setIsLoading(true);
      if(currentUser.isAdmin){
        url = `${companyId}/users`;
      }else{
        url = `${companyId}/users/find/${currentUser.userId}`;
      }

      const res = await userRequest.get(url);
      
      setData(res.data);

    } catch (error) {
      console.log(error.code);
      if (error.code === 403) {
        nav(`../login`);
      }
    }
    setIsLoading(false);
  };

  const deleteUsr = async (usr) =>{
    const res = await userRequest.delete(`${companyId}/users/${usr}`);

    if(res.data > 0){
       getUsers();
    }
    else{
     Swal.fire("Atenção!", "Erro ao excluir usuário!", "error");

    }
  }
  const onButtonDeleteClick = (e, row) => {
    if(currentUser.isAdmin){
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#EE8253',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonColor: '#d33',
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.isConfirmed) {

          deleteUsr(row);
        }
      })
    }
    else{
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir usuário!",
        "warning"
      );
    }
};

const onButtonEditarClick = (e, row) => {
    nav(window.location.pathname.replace("tabconfigs","users")+"/"+ row );
};

  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };

  const columns = [
    {
      field: "userId",
      headerName: "Cod. Usuário",
      width: 150,
      align: "center",headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
    },
    { field: "name", headerName: "Nome", width: 200,align: "left",headerAlign: "left",headerClassName:'dataGridMuiHeader',},
    { field: "email", headerName: "Email", width: 200,align: "left",headerAlign: "left",headerClassName:'dataGridMuiHeader',},
    { field: "contact", headerName: "Contato", width: 120,align: "center",headerAlign: "center" ,headerClassName:'dataGridMuiHeader',},
    { field: "position", headerName: "Cargo", width: 120, align: "center",headerAlign: "center",headerClassName:'dataGridMuiHeader',},
    { field: "username", headerName: "Nome de Usuário", width: 120, align: "center",headerAlign: "center",headerClassName:'dataGridMuiHeader',},
    { field: "cpf", headerName: "CPF", width: 120, align: "center",headerAlign: "center",headerClassName:'dataGridMuiHeader',},
    { field: "isAdmin", headerName: "Administrador", width: 120, align: "center",headerAlign: "center",headerClassName:'dataGridMuiHeader',},
    { field: "createdAt", headerName: "Criada em", width: 200, align: "left",headerClassName:'dataGridMuiHeader', valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value), },
    { field: "updatedAt", headerName: "Atualizada em", width: 200, align: "left",headerClassName:'dataGridMuiHeader',  valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value), },
    {
      field: "editar",
      headerName: "",
      width: 80,
      align: "center",headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
          <IconButton onClick={(e) => onButtonEditarClick(e, params.row.userId)}>
          <EditIcon/>
          </IconButton>
          </>
        );
      },
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",headerAlign: "center",
      renderCell: (params) => {
        return (
          
          <IconButton  onClick={(e) => onButtonDeleteClick(e, params.row.userId)}
          ><DeleteOutline/>
          </IconButton>
          
        );
      }
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton/>
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
          <div className="div_custom_UpdateRight">
          <button onClick={addRow} className="button_custom_action_add_top">
          Adicionar Usuário
        </button>
          </div>
          <div className="div_custom_UpdateLeft">
          <DataGridPremium autoHeight 
          sx={{
                      fontFamily:"Manrope",
                      fontWeight:"500",
                      border:"0px"
                    }}
                    rowHeight={30} 
          localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
          slots={{ toolbar: CustomToolbar }}
          getRowId={(row) => row.userId}
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={8} />
          </div>
        </div>
        </div>
      )}
    </div>
  );
}
