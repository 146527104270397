import "../../global.css";
import "./color_sizes.css";
import "../../components/DragDropFiles.css";
import { useEffect, useState } from "react";
import { userRequest, publicRequestSt } from "../../requestMethods";
import * as React from "react";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";

export default function ColorsSizes() {
  const [colors, setColors] = useState([{}]);
  const [sizes, setSizes] = useState([{}]);
  const [colorsCompany, setColorsCompany] = useState([]);
  const [sizesCompany, setSizeCompany] = useState([]);
  const [grid, setGrid] = useState([]);
  const {companyId} = useCompany();

  useEffect(() => {

    if(companyId>0){
    getColors();
    getSizes();

    if (colorsCompany.length === 0) {
      getColorsCompany();
    }
    if (sizesCompany.length === 0) {
      getSizesCompany();
    }
    if (grid.length === 0) {
      getGrid();
    }
    }
  }, [companyId]);

  const getColorsCompany = async () => {
    const res = await userRequest.get(`${companyId}/products/colors`);

    res.data.map((item) => setColorsCompany((prev) => [...prev, item.id]));
  };
  const getSizesCompany = async () => {
    const res = await userRequest.get(`${companyId}/products/sizes`);

    res.data.map((item) => setSizeCompany((prev) => [...prev, item.id]));
  };

  const getGrid = async () => {
    const res = await userRequest.get(`${companyId}/grid`);
    setGrid(res.data);
  };
  const getColors = async () => {
    const res = await publicRequestSt.get("/standard/colors");
    setColors(res.data);
  };

  const getSizes = async () => {
    const res = await publicRequestSt.get("/standard/sizes");
    setSizes(res.data);
  };
  const cadastrarClick = () => {
    createColorSize();
  };

  const createColorSize = async () => {
    const arrayColors = colorsCompany.map((item) =>
      colors.find((c) => c.colorId === item)
    );
    const arraySizes = sizesCompany.map((item) =>
      sizes.find((s) => s.sizeId === item)
    );

   
    const resColor = await userRequest.post(`${companyId}/products/colors`, arrayColors);
    const resSize = await userRequest.post(`${companyId}/products/sizes`, arraySizes);


    if ( Object.keys(resColor.data).length > 0 &&  Object.keys(resSize.data).length > 0) {
      Swal.fire("Parabéns!", "Cores e Tamanhos cadastrados!", "success");
    } else {
      Swal.fire("Erro!", "Erro ao gravar Cores e Tamanhos!", "error");
    }
  };

  const deleteColor = async (colorId) =>{
    
    const res = await userRequest.delete(`${companyId}/products/colors/${colorId}`);
  }
  const deleteSize = async (sizeId) =>{
    
    const res = await userRequest.delete(`${companyId}/products/sizes/${sizeId}`);
  }  
  const handleChangeSize = (e) => {
    if (e.target.checked) {
      setSizeCompany((prev) => [...prev, parseInt(e.target.value)]);
    } else {
        
       const existProduct =  grid.find((g) =>g.sizeId === parseInt(e.target.value));
      if (!existProduct) {
        
        setSizeCompany((prev) => [
          ...prev.filter((item) => !(item === parseInt(e.target.value))),
        ]);
        deleteSize(parseInt(e.target.value));
      }
      else{
        Swal.fire("Atenção!", "Você não pode excluir esse tamanho pois existem produtos atrelados a ele.", "warning");
      }
    }
  };
  const handleChangeColor = (e) => {
    if (e.target.checked) {
      setColorsCompany((prev) => [...prev, parseInt(e.target.value)]);
    } else {
        const existProduct =  grid.find((g) =>g.colorId === parseInt(e.target.value));
  
        if (!existProduct) {
      setColorsCompany((prev) => [
        ...prev.filter((item) => !(item === parseInt(e.target.value))),
      ]);
      deleteColor(parseInt(e.target.value));
    }
    else{
        Swal.fire("Atenção!", "Você não pode excluir essa cor pois existem produtos atrelados a ela.", "warning");
    }
    }
  };
  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
        <div className="div_custom_Update">
          <div className="div_custom_UpdateLeft">
            <div>
              <label>Tabela de Tamanhos</label>
              <br></br>
              <table className="custom-minigrid-size">
                <thead>
                  <tr>
                    {sizes.map((size) => (
                      <td>{size.label}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {sizes.map((size) => (
                      <td>
                        <input
                          className="largerCheckbox"
                          type="checkbox"
                          value={size.sizeId}
                          onChange={handleChangeSize}
                          checked={
                            sizesCompany
                              ? sizesCompany.find(
                                  (item) => item === size.sizeId
                                  //item.sizeId === size.sizeId
                                )
                              : false
                          }
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <br></br>
            <div>
              <label>Tabela de Cores</label>
              <table className="custom-minigrid-prd">
                <thead>
                  <tr>
                    <td>Cores</td>
                    <td>Nomes</td>
                    <td>&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {colors.map((color, iColor) => (
                    <tr key={color.ref}>
                      <td bgcolor={color.rgb}></td>
                      <td>{color.label}</td>
                      <td>
                        <input
                          className="largerCheckbox"
                          type="checkbox"
                          value={color.colorId}
                          onChange={handleChangeColor}
                          checked={
                            colorsCompany
                              ? colorsCompany.find(
                                  (item) => item === color.colorId
                                )
                              : false
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="div_custom_UpdateRight">
            <button className="button_custom_action" onClick={cadastrarClick}>
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
