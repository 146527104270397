import axios from "axios";


export const UPLOAD_BASE_URL = process.env.NODE_ENV === 'production' 
                                  // ? "https://ecommerce-api.inchargeof.com/public/upload/"
                                  ? "https://mooda-space.nyc3.digitaloceanspaces.com/"
                                  : "http://localhost:8080/public/upload/";
                                  

const BASE_URL = process.env.NODE_ENV === 'production' 
? "https://api.mooda.app.br/api"
                          : "http://localhost:8080/api/";

// TODO: Definir quem pode usar essa API??? sem companyId
const BASE_URLST = process.env.NODE_ENV === 'production' 
? "https://api.mooda.app.br/api"
                    : "http://localhost:8080/api/";

// const TOKEN =
//   JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser
//     .accessToken || "";


export const nameMooda = "Mooda";
export const slugMooda = "mooda";
export const urlSiteMooda = "http://www.mooda.app.br/";
export const urlMooda="https://mobile.mooda.app.br/";
export const whatsAppCall="https://wa.me//55";

//const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY4NjIyOTc2NiwiZXhwIjoxNjg2NDg4OTY2fQ.stlcRCF6F58QYS6Ri1ta3utUPwhQTX4L4z3mdBEy_Ug"
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});
export const publicRequestSt = axios.create({
  baseURL: BASE_URLST,
});

export const userRequest = axios.create(
{
  baseURL: BASE_URL,
  //headers: { token: `Bearer ${TOKEN}` },
},);

userRequest.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  const currentUser = user && JSON.parse(user).currentUser;
  const TOKEN = currentUser?.accessToken;

    config.headers.token = `Bearer ${TOKEN}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});
userRequest.interceptors.response.use(

  (response) => {
      return response;
  },
  (error) => {
 
      if (error.response && (error.response.status === 403)) {

        window.localStorage.clear();

          var url = window.location.toString();
          url = url.replace(/\/[^\/]*$/, '/login')
          window.location.href = url;

          return Promise.reject('Unauthorized');
      }
      // Handle other errors here
      return Promise.reject(error);
  }
);

export const generateFileUrl = (image) => {
  const newUrl = `${UPLOAD_BASE_URL}${image}`;
  return newUrl;
};
// A	mobile.mooda.app.br	199.36.158.100
// A	admin.mooda.app.br	199.36.158.100
    // "injectQAConfig":"cp src/requestMethods.QA.js src/requestMethods.js",
