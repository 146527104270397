import "../../global.css";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { ptBR } from '@mui/x-data-grid/locales'
import * as React from "react";
import DetailsIcon from "@mui/icons-material/Details";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPricing } from "../../util/helper";
import Button from "@mui/material/Button";
import { formatTimeDDMMYYY, formatTime24hourFormat } from "../../util/helper";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Swal from "sweetalert2";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export default function CategoryList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [inputs, setInputs] = useState({});
  const [selectionModel2, setSelectionModel2] = useState();
  const [prdsTitle2, setprdsTitle2] = useState([{}]);

  useEffect(() => {
    setIsLoading(true);
    if (companyId > 0) {
      getCategories();
    }
    setIsLoading(false);
  }, [companyId]);

  const getCategories = async () => {
    const res = await userRequest.get(`${companyId}/products/categories`);
    setCategories(res.data);
  };

  const handleChangeCat = (e) => {
    setCategory(parseInt(e));
    if (category !== parseInt(e) && parseInt(e) > 0) {
      setSubCategories(
        categories.find((item) => item.id === parseInt(e)).subCategories
      );
    }
  };
  const addCategory = async () => {
    if (inputs.category !== "" && inputs.category !== undefined) {
      let cat = categories.find(
        (item) => item.label === inputs.category.toString().toUpperCase()
      );
      if (!cat) {
        const res = await userRequest.post(
          `${companyId}/products/categories/`,
          {
            label: inputs.category,
          }
        );
        if (Object.keys(res.data).length > 0) {
          Swal.fire("Parabéns!", "Categoria criada com sucesso!", "success");
          getCategories();
        }
      } else {
        Swal.fire("Atenção!", "Categoria existente!", "warning");
      }
    } else {
      Swal.fire(
        "Atenção!",
        "O campo Categoria deve ser preenchido.",
        "warning"
      );
    }
  };

  const addSubCategory = async () => {
    if (inputs.subcategory !== "" && inputs.subcategory !== undefined) {
      let cat = subCategories.find(
        (item) => item.label === inputs.subcategory.toString().toUpperCase()
      );

      if (!cat) {
        const res = await userRequest.post(
          `${companyId}/products/categories/`,
          {
            label: inputs.subcategory,
            parentCategoryId: category,
          }
        );

        console.log();
        if (Object.keys(res.data).length > 0) {
          Swal.fire(
            "Parabéns!",
            "Sub Categoria criada com sucesso!",
            "success"
          );
          getCategories();
          setSubCategories([]);
        }
      } else {
        Swal.fire("Atenção!", "Sub Categoria existente!", "warning");
      }
    } else {
      Swal.fire(
        "Atenção!",
        "O campo Sub Categoria deve ser preenchido.",
        "warning"
      );
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onButtonOffClick = async (e, categoryId) => {
    const res = await userRequest.put(
      `${companyId}/products/categories/logicaldelete/${categoryId}`,
      { deleted: new Date() }
    );

    if (Object.keys(res.data).length > 0) {
      getCategories();
      setSubCategories([]);
    } else {
      Swal.fire("Atenção!", "Erro ao desabilitar categoria!", "error");
    }
  };
  const onButtonOnClick = async (e, categoryId) => {
    const res = await userRequest.put(
      `${companyId}/products/categories/logicaldelete/${categoryId}`,
      { deleted: null }
    );

    if (Object.keys(res.data).length > 0) {
      getCategories();
      setSubCategories([]);
    } else {
      Swal.fire("Atenção!", "Erro ao desabilitar categoria!", "error");
    }
  };
  const columns = [
    {
      field: "deleted",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        if (params.row.deleted === null || params.row.deleted === null) {
          return (
            <IconButton onClick={(e) => onButtonOffClick(e, params.row.id)}>
              <VisibilityOutlinedIcon />
            </IconButton>
          );
        } else {
          return (
            <IconButton onClick={(e) => onButtonOnClick(e, params.row.id)}>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          );
        }
      },
    },
    {
      field: "id",
      headerName: "Id",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader',
    },
    {
      field: "label",
      headerName: "Categoria",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader',
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
            <div className="div_custom_UpdateItem">
              <label>Categorias</label>
            </div>
            <div >
              <label className="custom_label">Nome Categoria:</label>
              <input
                type="text"
                name="category"
                onChange={handleChange}
                maxLength="100"
              />
              <button
                size="small"
                onClick={addCategory}
                className="button_custom_action_add_top"
              >
                Adicionar Categoria
              </button>
            </div>
            <div className="div_custom_UpdateLeft">
              <DataGridPremium
                          sx={{
                            fontFamily:"Manrope",
                            fontWeight:"500",
                            border:"0px"
                          }}
                          rowHeight={30} 
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                onRowSelectionModelChange={handleChangeCat}
                getRowId={(row) => row.id}
                rows={categories}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
              />
            </div>
            <div className="div_custom_UpdateItem">
            <label>Sub Categorias</label>
            </div>
            <div>
              <label className="custom_label">Nome Sub Categoria:</label>
              <input
                type="text"
                name="category"
                onChange={handleChange}
                maxLength="100"
              />
              <button
                size="small"
                onClick={addSubCategory}
                className="button_custom_action_add_top"
              >
                Adicionar Sub Categoria
              </button>
            </div>
            <div className="div_custom_UpdateLeft">
            <DataGridPremium
                        sx={{
                          fontFamily:"Manrope",
                          fontWeight:"500",
                          border:"0px"
                        }}
                        rowHeight={30} 
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
              getRowId={(row) => row.id + row.label}
              rows={subCategories}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
            />

            </div>            
          </div>
        </div>
      )}
    </div>
  );
}
