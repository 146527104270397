import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ImportCategory from '../categories/importCategory'
import ImportProducts from '../product/importProducts'
import ImportStock from '../product/importStock'
import ImportCustomer from '../customer/importCustomer'
import UserList from '../user/userList';
import CategoryList from '../categories/categoryList';
import ColorsSizes from '../config/color_sizes';
import Highlights from '../highlights/highlights';
import Company from '../company/company';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabImport() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      minWidth: 0,
      [theme.breakpoints.up('sm')]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#EE8253',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#EE8253',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
      },
    }),
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <AntTab label="Usuários" {...a11yProps(0)} />
          <AntTab label="Categorias" {...a11yProps(1)} />
          <AntTab label="Tamanho e Cores" {...a11yProps(2)} />
          <AntTab label="App Mobile" {...a11yProps(3)} />
          <AntTab label="Empresa" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserList/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CategoryList/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
       <ColorsSizes />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
       <Highlights/>
      </CustomTabPanel> 
      <CustomTabPanel value={value} index={4}>
       <Company/>
      </CustomTabPanel>        
    </Box>
  );
}