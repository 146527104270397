import "../../global.css";
import { setState } from "../../redux/dataGridRedux";
import { persistor } from "../../redux/store";
import { ptBR } from '@mui/x-data-grid/locales'
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarColumnsButton,
  useGridApiRef
} from "@mui/x-data-grid-premium";
import * as React from "react";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import Swal from "sweetalert2";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import MessageIcon from "@mui/icons-material/Message";
import Box from "@mui/material/Box";
import { formatPricing } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";

export default function CustomerList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  //  console.log("currentUser",currentUser);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();
  const [cartC, setcartC] = useState(false);
  const [orderC, setorderC] = useState(false);
  const apiRef = useGridApiRef();
  const dataGridState = useSelector((state) => state.DataGridPremium.stateGrid);
  const [savedState, setSavedState] = React.useState();
  function filterModelChanged() {
    dispatch(
      setState({
        initialState: apiRef.current.exportState(),
      })
    );
  }

  useEffect(() => {
    if (companyId > 0) {
      getCustomers();
    }
  }, [companyId]);

  const getCustomers = async () => {
    try {
      let url = "";
      setIsLoading(true);
      if (currentUser.isAdmin) {
        url = `${companyId}/customers`;
      } else {
        url = `${companyId}/customers/managedBy/${currentUser.userId}`;
      }

      const res = await userRequest.get(url);
      if (res.data) {
        setData(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const deleteCustomer = async (customer) => {
    const res = await userRequest.delete(`${companyId}/customers/${customer}`);
    if (res.data > 0) {
      getCustomers();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir cliente!", "error");
    }
  };

  const deleteLogico = async (customer) => {
    try {
      const res = await userRequest.put(`${companyId}/customers/${customer}`, {
        deleted: new Date(),
      });
      if (Object.keys(res.data).length > 0) {
        getCustomers();
      } else {
        Swal.fire("Atenção!", "Erro ao excluir cliente!", "error");
      }
    } catch (error) {}
  };
  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //deleteCustomer(row);
          deleteLogico(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir cliente!",
        "warning"
      );
    }
  };

  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };

  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };

  const columns = [
    {
      field: "customerId",
      headerName: "Cod. Cliente",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "enabled",
      headerName: "Visão dos Preços",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        switch (params.value) {
          case "Aprovado":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
            break;
          case "Negado":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
            break;
          default:
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "owner_cpf",
      headerName: "CPF",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "cnpj_cpf",
      headerName: "CNPJ",
      width: 160,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "rzSocial",
      headerName: "Razão Social",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "managedBy",
      headerName: "Vendedor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "onlineStore",
      headerName: "Inta ou Loja virtual",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "orderAmount",
      headerName: "Valor último pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "orderDate",
      headerName: "Data último pedido",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "cartTotal",
      headerName: "Valor carrinho",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "cartDate",
      headerName: "Data carrinho",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "createdAt",
      headerName: "Criado",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => {return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "updatedAt",
      headerName: "Atualizado",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => {return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "acessAt",
      headerName: "Último Acesso",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),
      type: "date",
    },
    {
      field: "editar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) => onButtonEditarClick(e, params.row.customerId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "message",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        if (params.row.managedBy !== null) {
          return (
            <IconButton
              target="_blank"
              href={`https://wa.me//55${
                params.row.contactManagedBy
              }?text=Cliente%20${
                params.row.name
              }%20já%20liberado%20para%20compras,segue:
            %0DNome:${params.row.name}
            %0DRzSocial:${params.row.rzSocial ? params.row.rzSocial : ""}
            %0DCPF:${params.row.owner_cpf ? params.row.owner_cpf : ""}
            %0DCNPJ:${params.row.cnpj_cpf ? params.row.cnpj_cpf : ""}
            %0DContato:${params.row.contact ? params.row.contact : ""}
            %0DEmail:${params.row.email ? params.row.email : ""}
            %0DEnd:${params.row.address.street ? params.row.address.street : ""}
            %0DComplemeto:${
              params.row.address.complement ? params.row.address.complement : ""
            }
            %0DCidade:${params.row.address.city ? params.row.address.city : ""}
            %0DUF:${params.row.address.uf ? params.row.address.uf : ""}
            %0DInstaLojaVirtual:${
              params.row.onlineStore ? params.row.onlineStore : ""
            }
            `}
            >
              <MessageIcon />
            </IconButton>
          );
        }
      },
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.customerId)}
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="div_custom_Container">
      {isLoading ? (
        <Loading />
      ) : (
          <div className="div_custom_UpdateList">
          <div className="div_custom_UpdateRight">
            <button onClick={addRow} className="button_custom_action_add_top">
              Adicionar Cliente
            </button>
          </div>
          <div className="div_custom_UpdateLeft">
            <DataGridPremium
                    sx={{
                      fontFamily:"Manrope",
                      fontWeight:"500",
                      border:"0px",
                    }}
                    autoHeight
                    rowHeight={30}                    
              initialState={dataGridState.initialState}
              onFilterModelChange={filterModelChanged}
              apiRef={apiRef}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
              getRowId={(row) => row.customerId}
              rows={data}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
            />
          </div>
        </div>
      )}
    </div>
  );
}
