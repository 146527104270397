import "./sidebarComponent.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Button from '@mui/material/Button';
import { Sidebar, Menu, MenuItem,menuClasses } from "react-pro-sidebar";
import logoFull from "../icons/mooda_logo_full.svg";
import orderIcon from "../icons/orderIcon.svg";
import products from "../icons/products.svg";
import clients from "../icons/clients.svg";
import dash from "../icons/dashboard.svg";
import config from "../icons/settings.svg";
import menuIcon from "../icons/menuIcon.svg";
import home from "../icons/home.svg";
import importacao from "../icons/importacao.svg"


export default function SidebarComponent() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [collapsed, setCollapsed] = useState(false);

  const collapse = () =>  {
    setCollapsed(!collapsed)
  }

  return (

      <Sidebar width="200px" collapsed={collapsed} collapsedWidth="60px" 
              rootStyles={{
                backgroundImage:"linear-gradient( to right, #FF3131, #FF914C )",
                fontWeight:"700",
                color:"white",
              }}
      >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%',backgroundImage:"linear-gradient( to right, #FF3131, #FF914C )" }}>
      <Menu
          menuItemStyles={{
            icon: { padding: "0px" },
          }}
          rootStyles={{
            backgroundImage:"linear-gradient( to right, #FF3131, #FF914C )"
          }}
        >
      <MenuItem icon={      <Button
          onClick={collapse}
          
        >
          <img src={menuIcon} />
        </Button>}
        >

      <div className="customLogo">
          <img src={logoFull} />
        </div>
        
          </MenuItem>
          <MenuItem
            icon={<img src={home} />}
            component={<Link to={`home`} />}
          >
            Home
          </MenuItem>
          <MenuItem
            icon={<img src={clients} />}
            component={<Link to={`customers`}/>}
          >
            Clientes
          </MenuItem>
          <MenuItem
            icon={<img src={orderIcon} />}
            component={<Link to={`orders`} />}
          >
            {" "}
            Pedidos
          </MenuItem>
          <MenuItem
            icon={<img src={products} />}
            component={<Link to={`products`}/>}
          >
            {" "}
            Produtos
          </MenuItem>
          <MenuItem
            icon={<img src={dash} />}
            component={<Link to={`tabdashboard`} />}
          >
            {" "}
            Dashboard
          </MenuItem>
          <MenuItem
            icon={<img src={config} />}
            component={<Link to={`tabconfigs`} />}
          >
            {" "}
            Configurações
          </MenuItem>{" "}
          <MenuItem
            icon={<img src={importacao} />}
            component={<Link to={`tabimports`} />}
          >
            {" "}
            Importações
          </MenuItem>
        </Menu>
        </div>
      </Sidebar>
  );
}
