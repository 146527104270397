import "../../global.css";
import "./campaignList.css";
import { DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { ptBR } from '@mui/x-data-grid/locales'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import { formatTimeDDMMYYY } from "../../util/helper";
import { DeleteOutline } from "@mui/icons-material";
import { useCompany } from "../../components/context/CompanyContext";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function CampaignList() {
  const [campaigns, setCampaigns] = useState([{}]);
  const {companyId} = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if(companyId > 0){
    getCampaigns()
    }
  }, [companyId]);

  useEffect(() => {
   
  }, [campaigns]);

  const getCampaigns = async () => {
    try {
      const res = await userRequest.get(`${companyId}/campaign`);


      
        setCampaigns(
          res.data.map(item=>(
            {
            campaignId: item.campaignId,
            title: item.title,
            subTitle: item.subTitle,
            products: item.products.length,
            expiryDate: item.expiryDate,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
    }))
          );
    } catch {
      console.log();
    }
  };
  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };
  const deleteCp = async (cp) => {
    const res = await userRequest.delete(`${companyId}/campaign/${cp}`);

    if(res.data > 0){
    getCampaigns();
    }
    else{
      Swal.fire("Atenção!", "Erro ao excluir campanha!", "error");
 
     }

  }
  const onButtonDeleteClick = async (e, row) => {
    if(currentUser.isAdmin){
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#EE8253',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonColor: '#d33',
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.isConfirmed) {

          deleteCp(row);
        }
      })
    }
    else{
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o produto!",
        "warning"
      );
    }
  };

  const columns = [
    { field: "campaignId", headerName: "ID Camapanha", width: 200, align: "left",hide:false},
    {
      field: "title",
      align: "left",
      headerName: "Campanha",
      width: 200,
    },
    { field: "products", headerName: "Qtde Produtos", width: 200, align: "left" },
    { field: "createdAt", headerName: "Criada em", width: 200, align: "left",valueFormatter: (params) => formatTimeDDMMYYY(params?.value),type: "date" },
    { field: "updatedAt", headerName: "Atualizada em", width: 200, align: "left",valueFormatter: (params) => formatTimeDDMMYYY(params?.value), type: "date"},
    {
      field: "action",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <IconButton
                     onClick={(e) => onButtonEditarClick(e, params.row.campaignId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "deletar",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.campaignId)}
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ];

  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton/>
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="list">
      <div>
        <button
          size="small"
          onClick={addRow}
          className="button_custom_action_add_top"
        >
          Adicionar Campanha
        </button>
      </div>
      <div>
      <DataGridPremium autoHeight rowHeight={25}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  slots={{ toolbar: CustomToolbar }}
        getRowId={(row) => row.campaignId + row.title}
        rows={campaigns}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
      />
    </div>
    </div>
  );
}
