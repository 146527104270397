import { useEffect, useState, useRef } from "react";
import { generateFileUrl, userRequest } from "../../requestMethods";
import { Link } from "react-router-dom";
import "../../global.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { ptBR } from '@mui/x-data-grid/locales'
import { getProducts } from "../../redux/apiCalls";
import { formatPricing,formatTimeYYYYMMDD } from "../../util/helper";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";


export default function Campaign() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [campaign, setCampaign] = useState({});
  const [inputs, setInputs] = useState({});
  const [prdsTitle1, setprdsTitle1] = useState();
  const [selectionModel1, setSelectionModel1] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const products = useSelector((state) => state.product.products);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const {companyId} = useCompany();

  useEffect(() => {
    setIsLoading(true);
    if(companyId > 0){
    getProducts(dispatch,false,companyId);
    }
    setIsLoading(false);
  }, [dispatch,companyId]);

  useEffect(() => {
    setIsLoading(true);
    if (id !== "new" && companyId > 0) {
      getCampaign();
    }
    setIsLoading(false);
  }, [id,companyId]);

  
  
  useEffect(() => {
      selectGrid1();
  }, [campaign]);

  const selectGrid1 = () => {
    if(campaign.products){
    setprdsTitle1(campaign.products);
      setSelectionModel1(() => campaign.products.map((item) => item));
  };
}
  const getCampaign = async () => {
    try {
      const res = await userRequest.get(`${companyId}/campaign/${id}`);

        setCampaign(res.data);
    } catch {
      console.log();
    }
  };

  const validate = () => {
    if (inputs.title === "" || inputs.title === undefined) {
      Swal.fire(
        "Atenção!",
        "Nome da Campanha deve ser preenchidos!",
        "warning"
      );
      return false;
    }
    if (inputs.expiryDate === "" || inputs.expiryDate === undefined) {
      Swal.fire(
        "Atenção!",
        "Data de Validade inválida!",
        "warning"
      );
      return false;
    }

    return true;

  };
  
  const validadePrds = () =>{
    
    
    if(prdsTitle1 === undefined || prdsTitle1.length <= 0){
      Swal.fire(
        "Atenção!",
        "Você deve selecionar no mímimo um produto!",
        "warning"
      );

      return false;
    }
    return true;

  }

  const updateClick = async (event) => {
    setIsLoading(true);


    if (id !== "new") {
      Update();
    } else {
      if (validate()) {
        if(validadePrds()){
        Inserir();}
      } 
    }
    setIsLoading(false);
  };

  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/campaign/${id}`, {
        title: inputs.title,
        subTitle: inputs.subTitle,
        products:prdsTitle1,
        expiryDate:inputs.expiryDate,
      });
      if(Object.keys(res.data).length > 0){
        Swal.fire("Parabéns!", "Campanha alterada com sucesso!", "success");
        nav(`../campaign`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar campanha!", "error");
      }
    } catch (error) {}
  };
  const Inserir = async () => {
    try {
      const res = await userRequest.post(`${companyId}/campaign`, 
        {
          title: inputs.title,
          subTitle: inputs.subTitle,
          products:prdsTitle1,
          expiryDate:inputs.expiryDate
        },
      );

      if(Object.keys(res.data).length > 0){
        Swal.fire("Parabéns!", "Campanha inserido com sucesso!", "success");
        nav(`../campaign`);
      } else {
        Swal.fire("Atenção!", "Erro ao inserir Campanha!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      field: "productId",
      headerName: "ID Produto",
      width: 200,
      align: "center",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {params.row.title}
          </div>
        );
      },
    },
    { field: "ref", headerName: "Referência", width: 200, align: "center" },
    { field: "category", headerName: "Categoria", width: 200, align: "left" },
    {
      field: "price",
      headerName: "Preço",
      width: 160,
      align: "left",
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "discount",
      headerName: "Desconto",
      width: 200,
      align: "left",
      valueFormatter: (params) => formatPricing(params?.value),
    },
  ];
  return (
    <div className="div_custom_">
       {isLoading ? <Loading /> : 
      <div className="div_custom_Container">
        <div className="div_custom_Update">
          {id !== "new" ? (
            <span className="div_custom_UpdateTitle">Editar Campanha</span>
          ) : (
            <span className="div_custom_UpdateTitle">Nova Campanha</span>
          )}
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Nome da Campanha</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="title"
                defaultValue={id !== "new" ? campaign.title || "" : ""}
                onChange={handleChange}
                maxLength="20"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Data de Validade</label>
              <input
                type="date"
                name="expiryDate"
                defaultValue={id !== "new" ? formatTimeYYYYMMDD(campaign.expiryDate): ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
              />
            </div>
            <div className="div_data_grid">
            <DataGridPremium
                rowHeight={25}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                getRowId={(row) => row.productId}
                rows={products}
                rowSelectionModel={selectionModel1}
                onRowSelectionModelChange={(itm) => {
                  setSelectionModel1(itm);
                  setprdsTitle1(itm);
                }}
                columns={columns}
                pageSize={8}
                checkboxSelection
              />
            </div>            
          </div>
          <div className="div_custom_UpdateRight">
            {id != "new" ? (
              <button className="button_custom_action" onClick={updateClick}>
                Alterar
              </button>
            ) : (
              <button className="button_custom_action" onClick={updateClick}>
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </div>
}
    </div>
  );
}
