import "./product.css";
import { useLocation } from "react-router-dom";
import "../../global.css";
import "../../components/DragDropFiles.css";
import { useEffect, useState, useRef } from "react";
import { useSelector ,useDispatch} from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import {
  userRequest,
  publicRequestSt,
  generateFileUrl,
} from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { DeleteOutline, PreviewSharp, UploadFile } from "@mui/icons-material";
import { formatPricing, formatPricingDatabase } from "../../util/helper";
import { v4 as uuid } from "uuid";
import Swal from "sweetalert2";
import * as React from "react";
import Resizer from "react-image-file-resizer";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";

export default function Product() {
  const location = useLocation();
  const productId = location.pathname.split("/")[3];
  const [product, setProduct] = useState();
  const [sizes, setSizes] = useState([{}]);
  const [colors, setColors] = useState([{}]);
  const [inputs, setInputs] = useState({});
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([{}]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const inputDiscount= useRef("");
  const inputPrice= useRef("");
  const inputfullPrice= useRef("");

  const [pDiscount, setPDiscount] = useState();
  const [price, setPrice] = useState();
  const [fullPrice, setFullPrice] = useState();


  const [files, setFiles] = useState({});
  const inputRef = useRef();
  const [grid, setGrid] = useState([]);
  const [filesIni, setFilesIni] = useState(false);
  const [catIni, setCatIni] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState();
  const products = useSelector((state) => state.product.products);
  const { companyId } = useCompany();
  const dispatch = useDispatch();
  const onSortEnd = (oldIndex, newIndex) => {
    setFiles((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  const unique_id = uuid();
  const nav = useNavigate();

  useEffect(() => {
    if (product) {
      if(product.enabled){
        setEnabled(product.enabled);
      }
      if (product.images !== null && product.images !== undefined) {
        if (product.images.length > 0) {
          setFilesIni(true);
          setFiles(product.images);
        } else {
          setFilesIni(false);
        }
      }
      if (product.grid !== null && product.grid !== undefined) {
        if (product.grid.length > 0) {
          setGrid(product.gridId);
        }
      }

      if (product.categoryId !== "" && product.categoryId !== undefined) {
        const cat = categories.find((c) => c.id === product.categoryId);

        if (cat) {
          setCategory(product.categoryId);
        } else {
          const subCat = categories.find((c) =>
            c.subCategories.find((s) => s.id === product.categoryId)
          );

          if (subCat) {
            setSubCategories(
              categories.find((item) => item.id === subCat.id).subCategories
            );
            setCategory(subCat.id);
            setSubCategory(product.categoryId);
          }
        }
      }
      if (product.category !== "" && product.category !== undefined) {
        setCategory(product.category);
        setCatIni(true);
      }
      if (product.discount !== null && product.discount !== undefined){
        setFullPrice(product.discount)
      }
      if (product.price !== null && product.price !== undefined){
        setPrice(product.price)
      }

      setPDiscount(calcDiscount(product.price,product.discount))
    }
  }, [product]);



  useEffect(() => {
    if (companyId > 0) {
      getSizes();
      getColors();
      getCategories();
      getProducts(dispatch, true, companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (productId !== "new") {
      getProduct();
    }
  }, [productId]);


  const getProduct = async () => {
    const res = await userRequest.get(`${companyId}/products/${productId}`);
    setProduct(res.data);
  };
  const getSizes = async () => {
    const res = await userRequest.get(`${companyId}/products/sizes`);
    setSizes(res.data);
  };
  const getColors = async () => {
    const res = await userRequest.get(`${companyId}/products/colors`);
    setColors(res.data);
  };
  const getCategories = async () => {
    const res = await userRequest.get(`${companyId}/products/categories`);
    setCategories(res.data);
  };
  const calcDiscount = (sPrice,sFullPrice) =>{
    return  parseFloat(((1-(sPrice/sFullPrice))*100).toFixed(1));
  }
  const calcPrice = (sFullPrice,sDiscount) =>{

    return parseFloat((sFullPrice-(sFullPrice*(sDiscount/100)).toFixed(2)));
  }
  const validate = () => {
    if (inputs.title === "" || inputs.title === undefined) {
      Swal.fire("Atenção!", "O campo Produto deve ser preenchido.", "warning");
      return false;
    }
    if (inputs.ref === "" || inputs.ref === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Referência deve ser preenchido.",
        "warning"
      );
      return false;
    }
    let ref = products.find(item => item.ref.toUpperCase() === inputs.ref.toString().toUpperCase());
    if(ref){
      Swal.fire(
        "Atenção!",
        "Essa Referência já está cadastrada.",
        "warning"
      );
      return false;
    }
    if (inputs.price === "" || inputs.price === undefined) {
      Swal.fire("Atenção!", "O campo Preço deve ser preenchido.", "warning");
      return false;
    }
    if (!subCategory && !category) {
      Swal.fire(
        "Atenção!",
        "O campo Categoria deve ser preenchido.",
        "warning"
      );
      return false;
    }
    if (grid.length === 0 || grid === undefined) {
      Swal.fire("Atenção!", "Você deve selecionar cor e tamanho.", "warning");
      return false;
    }

    return true;
  };

  const updateClick = async (event) => {
    setIsLoading(true);

    try {

        if (product) {
          AlterarProduto();
        } 
        else {
          if (validate()) {
            InserirProdut();
          }
        }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const AlterarProduto = async () => {

    const res = await userRequest.put(`${companyId}/products/${productId}`, {
      title: inputs.title,
      ref: inputs.ref,
      shortDesc: inputs.shortDesc,
      desc: inputs.desc,
      price: formatPricingDatabase(inputs.price),
      discount: formatPricingDatabase(inputs.discount),
      grid: grid,
      enabled:enabled,
      categoryId:
        subCategory > 0 ? subCategory : category > 0 ? category : null,
    });

    if (Object.keys(res.data).length > 0) {
      if (handleUpload(productId)) {

        if(filesIni){
        const res = await userRequest.put(`${companyId}/products/${productId}`, {
          images: files,
        });
      }
        Swal.fire("Parabéns!", "Produto alterado com sucesso!", "success");
        nav(`../products`);
      } else {
        Swal.fire("Erro!", "Erro ao gravar a imagem!", "warning");
      }
    } else {
      Swal.fire("Erro!", "Erro ao gravar o produto!", "warning");
    }
  };
  const InserirProdut = async () => {
    const res = await userRequest.post(`${companyId}/products/`, [
      {
        title: inputs.title,
        ref: inputs.ref,
        shortDesc: inputs.shortDesc,
        desc: inputs.desc,
        price: formatPricingDatabase(inputs.price),
        discount: formatPricingDatabase(inputs.discount),
        grid: grid,
        images: [],
        enabled:enabled,
        categoryId:
          subCategory > 0 ? subCategory : category > 0 ? category : null,
      },
    ]);

    res.data.map(async (item) => {
      if (!(await handleUpload(item.productId))) {
        Swal.fire("Erro!", "Erro ao gravar foto produto!", "warning");
      }

      setProduct(item);
    });

    if (Object.keys(res.data).length > 0) {
      Swal.fire("Parabéns!", "Produto adicionado com sucesso!", "success");
      nav(`../products`);
    } else {
      Swal.fire("Erro!", "Erro ao gravar o produto!", "error");
    }
    return res.data;
  };

  const handleChangeGrid = (stock,color,size) => {

    if (stock !== _findStock(color,size)) {

      setGrid((prev) => [
        ...prev.filter(
          (item) =>
            !(
              item.colorId === color &&
              item.sizeId === size
            )
        ),{ colorId: color, sizeId: size,stock:stock ? parseInt(stock):null },])
    } 
  };
  const handleChangeEnabled = (e) => {

    setEnabled(e.target.checked);

  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleChangeCat = (e) => {
    setCategory(parseInt(e.target.value));

    if (category !== parseInt(e.target.value) && parseInt(e.target.value) > 0) {
      setSubCategories(
        categories.find((item) => item.id === parseInt(e.target.value))
          .subCategories
      );
    }
  };
  const handleChangeDiscount = (e) => {
    setPDiscount(formatPricingDatabase(e.target.value));
    setPrice( calcPrice(fullPrice,formatPricingDatabase(e.target.value)));
  };
  const handleChangePrice = (e) => {
    setPrice(formatPricingDatabase(e.target.value));
    setPDiscount(calcDiscount(formatPricingDatabase(e.target.value),fullPrice));
  };
  const handleChangeFullPrice = (e) => {
    setFullPrice(formatPricingDatabase(e.target.value));
    setPrice(calcPrice(formatPricingDatabase(e.target.value),pDiscount));
  };
  const handleChangeSubCat = (e) => {
    setSubCategory(parseInt(e.target.value));
  };
  const handleUpload = async (productId) => {
    const formData = new FormData();

    try {
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          const photo = await resizeFile(files[i]);
          formData.append("image", photo);
        }
              if (!filesIni) {
        const res = await userRequest.post(
          `${companyId}/products/images/${productId}`,
          formData
        );
      }
      }



      return true;
    } catch (error) {
      console.log(">>>erroupload",error)
      return false;
    }
  };
  //https://github.com/onurzorluer/react-image-file-resizer#readme
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1350,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1080,
        1350
      );
    });
  const handleOnChange = (event) => {
    setFiles(event.target.files);
  };
  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };
  const handleOnDelete = (event) => {
    setFilesIni(false);
    setFiles({});

    const res = userRequest.delete(`${companyId}/products/images/${productId}`);

    if (res.data > 0) {
      return true;
    } else {
      return false;
    }
  };
  const _findStock =  (color,size) =>{
    
    const obj = grid.find(
      (item) =>
        item.colorId === color &&
        item.sizeId === size
    )
    return obj ? obj.stock:null;
  }
  return (
    <div className="div_custom_Container">
      {isLoading ? (
        <Loading />
      ) : (
          <div className="div_custom_Form">
            {product ? (
              <span className="div_custom_UpdateTitle">Editar Produto</span>
            ) : (
              <span className="div_custom_UpdateTitle">Novo Produto</span>
            )}
            <div className="div_custom_UpdateLeft">
              <div className="div_custom_UpdateItem">
                <label>Produto</label>
                <input
                  type="text"
                  className="div_custom_UpdateInput"
                  name="title"
                  defaultValue={product ? product.title : ""}
                  onChange={handleChange}
                  maxLength="50"
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Referência</label>
                <input
                  type="text"
                  name="ref"
                  defaultValue={product ? product.ref : ""}
                  className="div_custom_UpdateInput"
                  onChange={handleChange}
                  maxLength="15"
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Breve Descrição</label>
                <input
                  type="text"
                  name="shortDesc"
                  className="div_custom_UpdateInput"
                  defaultValue={product ? product.shortDesc : ""}
                  onChange={handleChange}
                  maxLength="100"
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Descrição</label>
                <textarea
                  rows="5"
                  type="text"
                  name="desc"
                  className="div_custom_UpdateInput_textArea"
                  defaultValue={product ? product.desc : ""}
                  onChange={handleChange}
                  maxLength="200"
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Preço Cheio</label>
                <input
                ref={inputfullPrice}
                  type="text"
                  name="discount"
                  className="div_custom_UpdateInput"
                  value={fullPrice ? formatPricing(fullPrice) : ""}
                  onChange={handleChangeFullPrice}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Desconto (%)</label>
                <input
                ref={inputDiscount}
                  type="text"
                  name="percentDiscount"
                  className="div_custom_UpdateInput"
                  value={pDiscount ? pDiscount.toString().replace(".",",") : ""}
                  onChange={handleChangeDiscount}
                />
              </div>                             
              <div className="div_custom_UpdateItem">
                <label>Preço</label>
                <input
                ref={inputPrice}
                  type="text"
                  name="price"
                  className="div_custom_UpdateInput"
                  value={price ? formatPricing(price) : ""}
                  onChange={handleChangePrice}
                />
              </div>
              <div>
                <label>Tamanhos e Cores</label>
                <table className="custom-minigrid-prd">
                  <thead>
                    <tr>
                      <td>&nbsp;</td>
                      {sizes.map((size) => (
                        <td key={size.ref}>{size.label}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {colors.map((color, iColor) => (
                      <tr key={color.ref}>
                        <td bgcolor={color.rgb}></td>
                        {sizes.map((size, iSize) => (
                          <td>
                <input
                  type="number"
                  className="div_custom_Input"
                  name="grid"
                  defaultValue={grid
                    ? _findStock(color.id,size.id)
                    : null}
                  onChange={e => handleChangeGrid(e.target.value,color.id,size.id)}
                />                            
                            {/* <input
                              className="largerCheckbox"
                              type="checkbox"
                              name={color.id}
                              value={size.id}
                              checked={
                                grid
                                  ? grid.find(
                                      (item) =>
                                        item.colorId === color.id &&
                                        item.sizeId === size.id
                                    )
                                  : false
                              }
                              //checked={isChecked}
                              onChange={handleChangeGrid}
                            /> */}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="div_custom_UpdateItem">
                <label>Categoria</label>
                <select
                  className="div_custom_Select"
                  name="category"
                  id="position"
                  onChange={handleChangeCat}
                  value={category && categories ? category : 0}
                >
                  <option value="0"></option>
                  {categories
                    ? categories.map((item) => (
                        <option value={item.id}>{item.label}</option>
                      ))
                    : false}
                </select>
              </div>
              <div className="div_custom_UpdateItem">
                <label>Sub Categoria</label>
                <select
                  className="div_custom_Select"
                  name="subCategory"
                  id="position"
                  onChange={handleChangeSubCat}
                  value={category && subCategories ? subCategory : 0}
                >
                  <option value="0"></option>
                  {subCategories
                    ? subCategories.map((item) => (
                        <option value={item.id}>{item.label}</option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="div_custom_UpdateItem">
                <label>Produto Visível</label>
                <input
                              className="largerCheckbox"
                              type="checkbox"
                              name="enabled"
                              checked={enabled}
                              onChange={handleChangeEnabled}
                            />
              </div>              
            </div>
            <div className="div_custom_UpdateItem">
              <label>Fotos</label>
            </div>
            <div>
              {files.length > 0 ? (
                <div className="uploads">
                  <SortableList onSortEnd={onSortEnd}>
                    {Array.from(files).map((item, index) => (
                      <SortableItem key={item}>
                        <img
                          className="productUploadImg2"
                          src={
                            filesIni
                              ? generateFileUrl(item)
                              : URL.createObjectURL(item)
                          }
                        />
                      </SortableItem>
                    ))}
                  </SortableList>
                  <div>
                    <button
                      className="button_custom_import"
                      onClick={handleOnDelete}
                    >
                      Excluir Fotos
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    multiple
                    onChange={handleOnChange}
                    hidden
                    accept="image/png, image/jpeg"
                    ref={inputRef}
                  />
                  <button
                    onClick={handleOnclick}
                    className="button_custom_import"
                  >
                    Selecione as fotos
                  </button>
                </div>
              )}
            </div>
            <div className="div_custom_UpdateRight">
              {product ? (
                <button className="button_custom_action" onClick={updateClick}>
                  Alterar
                </button>
              ) : (
                <button className="button_custom_action" onClick={updateClick}>
                  Criar
                </button>
              )}
            </div>
          </div>
      )}
    </div>
  );
}
