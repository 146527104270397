import "../../global.css";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import * as React from "react";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPricing } from "../../util/helper";
import Button from '@mui/material/Button';
import { formatTimeDDMMYYY,formatTime24hourFormat } from "../../util/helper";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading"
import { DeleteOutline } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';

export default function LeadList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rejections, setRejections] = useState([]);
const {companyId} = useCompany();


  useEffect(() => {
    setIsLoading(true);
    if(companyId > 0){
      getLeads();
      getReason();
    }
    setIsLoading(false);
  }, [companyId]);

  useEffect(() => {
  }, [data]);
  const getReason = async () =>{
    const res = await userRequest.get(`${companyId}/rejections`);

    setRejections(res.data);
  } 
  const getLeads = async () => {

      const res = await userRequest.get(`${companyId}/leads`);

      setData(res.data.map(item=>({
        lead:item.lead,
        count:item.count,
        createdAt:item.createdAt,
        reason:item.leadRejection ? item.leadRejection.rejection:"",
      })))
  };

  const handleChange= (e) => {

  let lead = e.target.value.split("-");

    updateReason(lead[0],lead[1]);
        
  };

  const updateReason = async (rejectionId,lead) =>{


    const res = await userRequest.put(`${companyId}/leads/`,{rejectionId,lead});
  }

  const columns = [
    {
      field: "lead",
      headerName: "Lead",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "count",
      headerName: "Tentativas de cadastro",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    { field: "createdAt", headerName: "Última Tentativa", width: 200, align: "left",valueFormatter: (params) => formatTime24hourFormat(params?.value), type: "date"},
    {
      field: "reason",
      headerName: "Motivo",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        console.log(">>data.reason",data.reason)
        if(params.row.reason === ""){
        return (
          <>
                <select
                  className="noBorder"
                  name="reason"
                  onChange={handleChange}
                  value={data ? data.reason :""}
                >
                  <option value=""></option>
                  {rejections ? rejections.map(item => 
                    <option value={item.rejectionId+"-"+params.row.lead}>{item.rejection}</option>
                   ):false}
                </select>              
          </>
        )
                  }else{
                    return(
                    params.row.reason
                    )
                  }

      },
    },
  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="list">
       {isLoading ? <Loading /> : 
      <DataGridPremium 
      initialState={{
        sorting: {
          sortModel: [{ field: 'count', sort: 'desc' },],
        },
      }}      
      autoHeight rowHeight={25}
        localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
        slots={{ toolbar: CustomToolbar }}

        getRowId={(row) => row.lead}
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
      />}
    </div>
  );
}
