import { useEffect, useState,useRef} from "react";
import "./company.css";
import { generateFileUrl, userRequest } from "../../requestMethods";
import "../../global.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";


export default function Company() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [company, setCompany] = useState("");
  const [state, setState] = useState("");
  const [inputs, setInputs] = useState();
  const [states, setStates] = useState([]);
  const [file, setFile] = useState();
  const [fileList, setFileList] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [filesIni, setFilesIni] = useState();
  const inputRef = useRef();
  const inputRefname= useRef("");
  const inputRefcnpj= useRef("");
  const inputRefrzSocial= useRef("");
  const inputRefcontact= useRef("");
  const inputRefemail= useRef("");
  const inputRefaddress= useRef("");
  const inputRefCEP= useRef("");
  const inputRefcity= useRef("");
 const inputRefabout= useRef("");
 const inputRefPolicy=useRef("");
 const inputRefMinprd=useRef("");
 const {imgLogo,companyId} = useCompany();

  const nav = useNavigate();

  useEffect(() => {
    if(companyId>0){
    getCompany();
    getStates();
    }
  }, [companyId]);

  useEffect(() => {
 
  }, [file]);

  useEffect(() => {
    if(company){
      setState(company.state)
     
      if(imgLogo){
          setFilesIni(true);
        } 
        setFile(imgLogo);

      }

  }, [company]);
  const getCompany = async ()=>{
    try {

      const res = await userRequest.get(`${companyId}/company`);

      setCompany(res.data);
    } catch (error) {}

  }
  const getStates = async () =>{
    const res = await userRequest.get(`${companyId}/customers/state`);

     setStates(res.data);
  }
  const validate = () => {

    if (inputRefname.current.value === "" || inputRefname.current.value === undefined) {
      return false;
    }
    if (inputRefemail.current.value === "" || inputRefemail.current.value === undefined) {
      return false;
    }
    if (inputRefcontact.current.value === "" || inputRefcontact.current.value === undefined) {
      return false;
    }
    if (inputRefcnpj.current.value === "" || inputRefcnpj.current.value === undefined) {
      return false;
    }
    if (inputRefrzSocial.current.value === "" || inputRefrzSocial.current.value=== undefined) {
      return false;
    }
    if (inputRefaddress.current.value === "" || inputRefaddress.current.value  === undefined) {
      return false;
    }
    if (inputRefCEP.current.value === "" || inputRefCEP.current.value === undefined) {
      return false;
    }
    if (inputRefcity.current.value === "" || inputRefcity.current.value === undefined) {
      return false;
    }
    if (!state) {
      return false;
    }        
    return true;
  };
  const updateClick = async (event) => {
 
    if(validate){
      let upload = true;
      if (!filesIni) {
        if(fileList.length === 1){
        upload = handleUpload();
        }
        else{
          Swal.fire(
            "Atenção!",
            "Você pode ter apenas 1 logo!",
            "warning");
        }
      }
      if(upload){
        Update();
      }
    }else{
        Swal.fire(
          "Atenção!",
          "Todos os campos devem ser preenchidos!",
          "warning");
        }
  };
  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/company/`,{
        name: inputRefname.current.value,
        cnpj: inputRefcnpj.current.value,
        rzSocial: inputRefrzSocial.current.value,
        contact: inputRefcontact.current.value,
        email: inputRefemail.current.value,
        address: inputRefaddress.current.value,
        CEP: inputRefCEP.current.value,
        city: inputRefcity.current.value,
        state:state,
        about:inputRefabout.current.value,
        exchangePolicy:inputRefPolicy.current.value,
        configRules: {minProducts:inputRefMinprd.current.value,}
      });
      if(Object.keys(res.data).length > 0){
        Swal.fire("Parabéns!", "Dados da empresa alterado com sucesso!", "success");
      } else {
        Swal.fire("Atenção!", "Erro ao alterar daddos!", "error");
      }
    } catch (error) {
      
    }
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangeState= (e) => {
    setState(e.target.value);
  };
  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };  
  const handleUpload = async (event) => {
    const formData = new FormData();

    if (fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        const photo = fileList[i];
        formData.append("image", photo);
      }
    }
    const res = userRequest.post(`${companyId}/products/images/logo`, formData);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
    const handleOnDelete = (event) => {
    setFilesIni(false);
    setFile();
    setFileList();

    const res = userRequest.delete(`${companyId}/products/images/deletelogo`);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnChange = (event) => {

    setFileList(event.target.files);
   Array.from(event.target.files).map(item =>{
      setFile(item);
    })
    
    setFilesIni(false);


  };
  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
        <div className="div_custom_Update">
            <span className="div_custom_UpdateTitle">Dados da Empresa</span>
            <div className="div_custom_UpdateItem">
            <label>Logo</label>
          </div>
          <div>
            
            {file && filesIni !== undefined  ? (
              <div className="uploads">              
                     <img
                       className="bannerUpload"
                       src={filesIni ? generateFileUrl(file): URL.createObjectURL(file)}
                     />

               <div>
                  <button
                    className="button_custom_import"
                    onClick={handleOnDelete}
                  >
                    Excluir Logo
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  multiple
                  onChange={handleOnChange}
                  hidden
                  accept="image/png, image/jpeg"
                  ref={inputRef}
                />
                <button
                  onClick={handleOnclick}
                  className="button_custom_import"
                >
                  Selecione o logo
                </button>
              </div>
            )}
          </div>
              <div className="div_custom_UpdateItem">
                <label>Nome</label>
                <input
                  type="text"
                  name="name"
                  defaultValue={company ? company.name || "" : ""}
                  className="div_custom_UpdateInput"
                  onChange={handleChange}
                  maxLength="200"
                  ref={inputRefname}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>CNPJ</label>
                <input
                  type="text"
                  pattern="\d*" 
                  name="cnpj"
                  className="div_custom_UpdateInput"
                  defaultValue={company ? company.cnpj || "" : ""}
                  onChange={handleChange}
                  maxLength="14"
                  ref={inputRefcnpj}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Razão Social</label>
                <input
                  type="text"
                  name="rzSocial"
                  className="div_custom_UpdateInput"
                  defaultValue={company ? company.rzSocial || "" : ""}
                  onChange={handleChange}
                  maxLength="200"
                  ref={inputRefrzSocial}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Telefone</label>
                <input
                  type="text"
                  name="contact"
                  onChange={handleChange}
                  className="div_custom_UpdateInput"
                  defaultValue={company ? company.contact || "" : ""}
                  maxLength="12"
                  ref={inputRefcontact}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  className="div_custom_UpdateInput"
                  defaultValue={company ? company.email || "" : ""}
                  onChange={handleChange}
                  maxLength="200"
                  ref={inputRefemail}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>Endereço</label>
                <input
                  type="text"
                  name="address"
                  onChange={handleChange}
                  className="div_custom_UpdateInput"
                  defaultValue={company? company.address || "" : ""}
                  maxLength="200"
                  ref={inputRefaddress}
                />
              </div>
              <div className="div_custom_UpdateItem">
                <label>CEP</label>
                <input
                  type="text"
                  name="CEP"
                  onChange={handleChange}
                  className="div_custom_UpdateInput"
                  defaultValue={company ? company.CEP || "" : ""}
                  maxLength="9"
                  ref={inputRefCEP}
                />
              </div>
              <div>
                <label>Estado</label>
                <select
                  className="div_custom_Select"
                  name="state"
                  onChange={handleChangeState}
                  value={state ? state :""}
                >
                  <option value=""></option>
                  {states ? states.map(item => 
                    <option value={item.stateId}>{item.state}</option>
                   ):false}
                </select>
              </div>                
              <div className="div_custom_UpdateItem">
                <label>Cidade</label>
                <input
                  type="text"
                  name="city"
                  onChange={handleChange}
                  className="div_custom_UpdateInput"
                  defaultValue={company ? company.city || "" : ""}
                  maxLength="8"
                  ref={inputRefcity}
                />
              </div>
              <div className="div_custom_UpdateItem">
              <label>Sobre a Marca</label>
              <textarea
              rows="10"
                type="text"
                name="about"
                className="div_custom_UpdateInput_textArea"
                defaultValue={company ? company.about : ""}
                onChange={handleChange}
                maxLength="5000"
                ref={inputRefabout}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Política de Troca</label>
              <textarea
              rows="10"
                type="text"
                name="exchangePolicy"
                className="div_custom_UpdateInput_textArea"
                defaultValue={company ? company.exchangePolicy : ""}
                onChange={handleChange}
                maxLength="5000"
                ref={inputRefPolicy}
              />
            </div>

            <div className="div_custom_UpdateItem">
                <label>Quantidade mínima de produtos a ser vendidos</label>
                <input
                  type="text"
                  name="city"
                  onChange={handleChange}
                  className="div_custom_UpdateInput"
                  defaultValue={company && company.configRules ? company.configRules.minProducts || "" : ""}
                  maxLength="8"
                  ref={inputRefMinprd}
                />
              </div>                  
            <div className="div_custom_UpdateRight">
                <button className="button_custom_action" onClick={updateClick}>
                  Cadastrar
                </button>
                </div>                                  
            </div>

      </div>
    </div>
  );
}
