import React, { createContext, useState, useContext, useEffect } from "react";
import { publicRequest,urlSiteMooda,nameMooda,slugMooda } from "../../requestMethods";


const initialCompanyContext = {
  minProduct: 15,
  imgLogo: "defaultLogo.svg",
  companyName: nameMooda,
  companyId:0,
  slug:slugMooda,
} 

export const CompanyContext = createContext();

export const CompanyContextProvider = (props) => {
  const [data, setData] = useState({
    configRules:{
      minProducts:0
    },
    configUI:{
      imgLogo:[]
    },
    name:nameMooda,
    companyId:0,
    slug:slugMooda
  });
  const [contextData, setContextData] = useState(initialCompanyContext);
  
  

  useEffect(()=>{
      const [_ignoreFirstSlash, rootParam] = window.location.pathname.split('/');

      getCompany(rootParam);
  },[])

  const [companyName,setCompanyName] = useState();
  const [logo,setLogo] = useState();
  const [minProducts,setMinProducts] = useState();
  const [name, setName] = useState();
  const { configRules, configUI, name: remoteName,slug,companyId } = data;

  useEffect(()=>{
    setContextData({
      minProduct: minProducts ?? initialCompanyContext.minProduct,
      imgLogo: logo ?? initialCompanyContext.imgLogo,
      companyName: name ?? initialCompanyContext.companyName,
      companyId: companyId ?? initialCompanyContext.companyId,
      slug:slug ?? initialCompanyContext.slug
    })
  },[logo,minProducts,name,companyId,slug])


  useEffect(() =>{
   
    if(configUI && configUI.imgLogo){
   configUI.imgLogo.map((item) => setLogo(item))
    }
    if(configRules && configRules.minProducts){
   setMinProducts(configRules.minProducts)
    }
   setName(remoteName);
    
  },[data]);

  const getCompany = async (currentCompany) => {
    try {
      const { href, pathname } = window.location;
      const res = await publicRequest.get(`/company/${currentCompany}`);
  
      if(Object.keys(res.data).length > 0 ){
        setData(res.data);
        }
        else{
          window.location.href = urlSiteMooda;
        }

    } catch (error) {}
  };
 
  return (
    <CompanyContext.Provider
      value={contextData}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export const WithCompanyContext = (Component) => {
  const MixinComponent = (props) => {
    return (
      <CompanyContextProvider>
        <Component {...props}/>
      </CompanyContextProvider>
    );
  }

  return MixinComponent;
}

export function useCompany() {
  const context = useContext(CompanyContext);
  return context;
}