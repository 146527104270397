import { useEffect, useState } from "react";
import { login } from "../../redux/apiCalls";
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { generateFileUrl } from "../../requestMethods";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import { logOut } from "../../redux/apiCalls";

const Login = () => {
  const home = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, error } = useSelector((state) => state.user);
  const [company, setCompany] = useState("");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const nav = useNavigate();
  const {imgLogo,companyId} = useCompany();

  useEffect(() => {
    console.log(">>>>>>currentUser1",currentUser,localStorage.length,localStorage)
    if(currentUser){
      if(Object.keys(currentUser).length > 0 && localStorage.length > 0){
        nav(`../home`);
      }else{
        Swal.fire("Atenção!", "Usuário ou senha inválido!", "warning");
      }
    };

  }, [currentUser]);

  useEffect(() => {
    logOut(dispatch);

  }, []);

  const handleClick = (e) => {

    if(companyId > 0){
    login(dispatch, { username, password },companyId);
    }


  };

  return (
    <div className="div_custom_">

    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
              <div>
        <img style={{
        height: "100px",
      }} src={generateFileUrl(imgLogo)}/>
        </div>
        <br></br>
        <br></br>
        <div>
      <input
        style={{ padding: 10, marginBottom: 20 }}
        type="text"
        placeholder="usuário"
        onChange={(e) => setUsername(e.target.value)}
      />
      </div>
      <div>
      <input
        style={{ padding: 10, marginBottom: 20 }}
        type="password"
        placeholder="senha"
        onChange={(e) => setPassword(e.target.value)}
      />
      </div>
      <div>
      <button onClick={handleClick} className="button_custom_action_add_top">
        Login
      </button>
      </div>
    </div>
    </div>
  );
};

export default Login;
