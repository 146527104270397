import React from "react";
import "./loading.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
        <div className="div_custom">
       Favor aguardar um instante os dados estão sendo processados.
       </div>
       <div className="spinner-container">
      <div className="loading-spinner"></div>
      </div>
    </div>
  );
}