import "../../global.css";
import "./productList.css";
import "./importProducts.css";
import "../config/paleta";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import Loading from "../../components/loading/loading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { formatPricing } from "../../util/helper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import { useCompany } from "../../components/context/CompanyContext";
import { generateFileUrl } from "../../requestMethods";

export default function ImportProducts() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [dataXLS, setDataXLS] = useState([]);
  const { companyId } = useCompany();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (companyId > 0) {
      getProducts(dispatch, true, companyId);
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    const grouped = groupBy(dataXLS);

    const uniqColorSizeOnGrid = dataXLS.reduce(
      (prev, cur) => {
        const { ref, size, stock } = cur;
        const { uRef, uSize } = prev;

        if (!uRef[ref]) uRef[ref] = ref;
        if (!uSize[size]) uSize[size] = size;

        return prev;
      },
      { uRef: {}, uSize: {} }
    );
  }, [dataXLS]);

  function groupBy(array) {
    return array.reduce((acc, item) => {
      var key = item.ref + "-" + item.size;

      if (!acc[key]) acc[key] = [];
      acc[key].push({ ref: item.ref, size: item.size, stock: item.stock });
      return acc;
    }, {});
  }

  const columns = [
    { field: "ref", headerName: "Referência",headerClassName:'dataGridMuiHeader',  width: 200, align: "left" },
    { field: "size", headerName: "Tamanho", headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    { field: "stock", headerName: "Qtde Estoque", headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
  ];

  const addXLS = async () => {
    try {
      if (dataXLS.length > 0) {
        setIsLoading(true);
        const product = Array.from(dataXLS).map((item) => ({
          ref: item.ref,
          size: item.size,
          stock: item.stock,
        }));

        const res = await userRequest.post(
          `${companyId}/products/stock`,
          product
        );
        setIsLoading(false);
        if (Object.keys(res.data).length > 0) {
          Swal.fire(
            "Parabéns!",
            "Estoque de produtos atualizado com sucesso!",
            "success"
          );
        } else {
          Swal.fire(
            "Atenção!",
            "Favor verificar a referêcia dos produtos, nenhum produto atualizado!",
            "warning"
          );
        }
      } else {
        Swal.fire("Atenção!", "Favor selecionar um aquivo!", "warning");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(">>>", err);
    }
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      if (validaFile(workbook)) {
        const index = workbook.SheetNames.findIndex(
          (item) => item === "Estoque"
        );
        const sheetName = workbook.SheetNames[index];
        const sheet = workbook.Sheets[sheetName];

        sheet["A1"].w = "ref";
        sheet["B1"].w = "size";
        sheet["C1"].w = "stock";

        const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
        const newParseData = parsedData.map((item) => ({
          ref: item.ref ? item.ref.replace(",", ".") : null,
          size: item.size ? item.size.trim().toUpperCase() : null,
          stock: item.stock ? item.stock : null,
        }));

        setDataXLS(newParseData);
      } else {
        Swal.fire("Erro!", "Estrutura do arquivo modificada.", "warning");
      }
    };
  };

  const validaFile = (workbook) => {
    const index = workbook.SheetNames.findIndex((item) => item === "Estoque");
    const sheetName = workbook.SheetNames[index];
    const sheet = workbook.Sheets[sheetName];

    if (index === -1) {
      return false;
    }
    if (sheet["A1"].v !== "Referencia") {
      return false;
    }
    if (sheet["B1"].v !== "Tamanho") {
      return false;
    }
    if (sheet["C1"].v !== "Qtde Estoque") {
      return false;
    }

    return true;
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  let downalodFIle = generateFileUrl("ImportFiles/ImportarEstoque.xlsx");
  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
        <div className="div_custom_UpdateList">
          <div>
            <input
              id="selecao-arquivo"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>
          <div>
            <button
              size="small"
              onClick={addXLS}
              className="button_custom_action_add_top"
            >
              Importar Excel
            </button>
          </div>
          <div>
            <labe>Informações para importação de estoque</labe>
            <br></br>
          </div>
          <div className="custom_label">
            <labe>
              1. O arquivo deve ser utilizado na mesma estrutura de download.
              <a href={downalodFIle} download>
                Download Arquivo
              </a>
            </labe>
          </div>
          <div>
            <br></br>
            {isLoading ? (
              <Loading />
            ) : (
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily:"Manrope",
                  fontWeight:"500",
                  border:"0px"
                }}
                rowHeight={30} 
                localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                getRowId={(row) => row.ref + row.size + row.stock}
                rows={dataXLS}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
