import { useEffect, useState,useRef} from "react";
import "./topbar.css";

import { logOut } from "../redux/apiCalls"
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { generateFileUrl } from "../requestMethods";
import { useCompany } from "./context/CompanyContext";
import ImportMenu from "./menu/ImportMenu";
import ReportMenu from "./menu/ReportMenu";
import AccountMenu from "./menu/AccountMenu";
import ConfigMenu from "./menu/ConfigMenu";
import HomeMenu from "./menu/HomeMenu";
import ProductMenu from "./menu/ProductMenu";
import CustomerMenu from "./menu/CustomerMenu";
import OrdersMenu from "./menu/OrdersMenu";
import CampaignMenu from "./menu/CampaignMenu";
import DashMenu from "./menu/DashMenu";

import { Divider } from "@mui/material";


export default function Topbar() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const {imgLogo}= useCompany();

  const handleClick = () =>{
    logOut(dispatch);
    nav(`login`);
  }
  useEffect(() => {

}, []);

  return (

    <table width="100%" className="tableBorder">
  <tr>
    <td width="95%" className="center"> <img className="logoimg" src={generateFileUrl(imgLogo)} />
    </td>
    <td width="5%"><AccountMenu/></td>
  </tr>
  </table>
     
  );
}
