import "./report.css";
import { useEffect, useMemo, useState } from "react";
import { userRequest } from "../../requestMethods";
import { formatTimeDDMMYYY } from "../../util/helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import { formatPricing } from "../../util/helper";
import dayjs from "dayjs";
import vendasColorida from "../../icons/vendasColorido.svg";
import clientesColorido from "../../icons/clientesColorido.svg";
import produtosColorido from "../../icons/produtosColorido.svg";
import { generateFileUrl } from "../../requestMethods";

export default function CustomerReport() {
  const [orders, setOrders] = useState();
  const [customers, setCustomers] = useState();
  const [carts, setCarts] = useState();
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();
  const nav = useNavigate();

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getCustomers();
      getCarts();
      getOrders();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getCustomers();
      getCarts();
      getOrders();
    }
  }, [dataIni, dataFim]);

  const getCustomers = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customers`,
          obj
        );

        setCustomers(res.data);
      }
    } catch {}
  };
  const getCarts = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCarts/carts`,
          obj
        );
        setCarts(res.data);
      }
    } catch {}
  };
  const getOrders = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrders/orders`,
          obj
        );
        setOrders(res.data);
      }
    } catch {}
  };

  // const getShares = async () => {
  //   try {
  //     let obj = {
  //       start: dataIni,
  //       end: dataFim,
  //     };

  //     const res = await userRequest.post(`${companyId}/eventUsers/share`, obj);

  //     setSharedQuantity(res.data);
  //   } catch {}
  // };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  return (
    <div className="div_home_Container">
      <div >
         <img className="custom_banner" src={generateFileUrl('bannerAdmin/banner.png')}/>

      </div>
      <div>
        <div className="datePicker">
          <label className="labelPeriodo">
            Filtrar:
            <input
              type="date"
              id="startDate"
              value={dataIni}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
              disabled={readonly !== "F"}
            />
            <input
              type="date"
              id="endDate"
              value={dataFim}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeEnd}
              disabled={readonly !== "F"}
            />
          </label>
        </div>
        <div className="column">
          <input
            type="radio"
            id="30"
            value="30"
            onChange={handleChange}
            checked={readonly === "30"}
          />
          <label for="30">30 dias</label>
          <input
            type="radio"
            id="15"
            value="15"
            onChange={handleChange}
            checked={readonly === "15"}
          />
          <label for="15">15 dias</label>
          <input
            type="radio"
            id="7"
            value="7"
            onChange={handleChange}
            checked={readonly === "7"}
          />
          <label for="7">7 dias</label>
          <input
            type="radio"
            id="0"
            value="0"
            onChange={handleChange}
            checked={readonly === "0"}
          />
          <label for="0">Hoje</label>
          <input
            type="radio"
            id="0"
            value="F"
            onChange={handleChange}
            checked={readonly === "F"}
          />
          <label for="0">Filtro</label>
        </div>
      </div>
      <table width="100%" className="tableSpace">
        <tr>
          <td width="50%">
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                    <td width="23%">
                        <img src={vendasColorida} />
                      </td>
                      <td width="77%"> 
                        <tr className="labelTitle">Vendas</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <table
                cellspacing="1"
                cellpadding="2"
                align="center"
                className="table"
              >
                <tr>
                  <td align="left" className="left">
                    Carrinho({carts && carts.quantity ? carts.quantity : 0})
                  </td>
                  <td align="right" className="right">
                    {carts && carts.total
                      ? formatPricing(carts.total)
                      : "R$ 0,00"}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Pedidos finalizados(
                    {orders && orders.cFinalizado ? orders.cFinalizado : 0})
                  </td>
                  <td align="right" className="right">
                    {orders && orders.aFinalizado
                      ? formatPricing(orders.aFinalizado)
                      : "R$ 0,00"}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Pedidos pendentes(
                    {orders && orders.cPendente ? orders.cPendente : 0})
                  </td>
                  <td align="right" className="right">
                    {orders && orders.aPendente
                      ? formatPricing(orders.aPendente)
                      : "R$ 0,00"}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Total pedidos(
                    {orders && orders.cTotal ? orders.cTotal : 0})
                  </td>
                  <td align="right" className="right">
                    {orders && orders.aTotal
                      ? formatPricing(orders.aTotal)
                      : "R$ 0,00"}
                  </td>
                </tr>
              </table>
              <div className="details">Ver mais detalhes</div>
            </div>
          </td>
          <td width="50%">
            <div className="div_home_custom">
            <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td width="23%">
                        <img src={clientesColorido} />
                      </td>
                      <td width="77%"> 
                        <tr className="labelTitle">Clientes</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>             
              <table
                cellspacing="1"
                cellpadding="2"
                align="center"
                className="table"
              >
                <tr>
                  <td align="left" className="left">
                    Novos clientes
                  </td>
                  <td align="right" className="right">
                    {customers && customers.newCustomer
                      ? customers.newCustomer
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Acesso aos catálogos
                  </td>
                  <td align="right" className="right">
                    {customers && customers.acess ? customers.acess : 0}%
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Qtde clientes
                  </td>
                  <td align="right" className="right">
                    {customers && customers.qtdeCustomer
                      ? customers.qtdeCustomer
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    <span>Clientes ativos</span>
                    <span className="labelMsgDataAtivos">
                      {" "}
                      (Últimos 90 dias)
                    </span>
                  </td>
                  <td align="right" className="right">
                    {customers && customers.ativos ? customers.ativos : 0}%
                  </td>
                </tr>
              </table>
              <div className="details">Ver mais detalhes</div>
            </div>
          </td>
        </tr>
        <tr>
          <td width="50%">
            <div className="div_home_custom">
            <table className="table">
                <tr>
                  <td align="left">
                    <tr>
                      <td width="23%">
                        <img src={produtosColorido}/>
                      </td>
                      <td width="77%">
                        <tr className="labelTitle">Produtos</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left">
                    {" "}
                  </td>
                </tr>
              </table>                          
              <table
                cellspacing="1"
                cellpadding="2"
                align="center"
                className="table"
              >
                <tr>
                  <td align="left" className="left">
                    Mais vendidos
                  </td>
                  <td align="right" className="right">
                    B.2058
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Sem estoque
                  </td>
                  <td align="right" className="right">
                    25
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Sem venda
                  </td>
                  <td align="right" className="right">
                    12
                  </td>
                </tr>
              </table>
              <div className="details">Ver mais detalhes</div>
            </div>
          </td>
        </tr>
      </table>
      {/* <FeaturedInfo dataIni={dataIni} dataFim={dataFim} />
          <Chart data={orders} title="Vendas por Dia" grid dataKey="Total" />

          <SalesAmount data={ordersUser} title="Vendas" />
          <Product
            data={ordersQuantity}
            dataIni={dataIni}
            dataFim={dataFim}
            title="Quantidade de Produtos"
          />
          <Shared
            data={sharedQuantity}
            dataIni={dataIni}
            dataFim={dataFim}
            title="Catálogo Compartilhados"
          /> */}
    </div>

    // <div className="div_custom_">
    //     <div className="div_custom_Container">
    //       <div className="div_custom_UpdateList">
    //       <div className="datePicker">
    //       <label>
    //       Período:
    //       <input
    //         type="date"
    //         id="startDate"
    //         //value={dataIni}
    //         name="trip-start"
    //         min="2023-01-01"
    //         //onChange={handleChangeStart}
    //       />
    //       <input
    //         type="date"
    //         id="endDate"
    //         //value={dataFim}
    //         name="trip-start"
    //         min="2023-01-01"
    //         //onChange={handleChangeEnd}
    //       />
    //     </label>

    //       </div>
    //       <div className="div_custom_UpdateLeft">
    //       <FeaturedInfo dataIni={dataIni} dataFim={dataFim} />
    //       </div>
    //     </div>
    //     </div>
    // </div>
  );
}
