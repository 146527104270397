import "../../global.css";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import * as React from "react";
import DetailsIcon from "@mui/icons-material/Details";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPricing } from "../../util/helper";
import Button from '@mui/material/Button';
import { formatTimeDDMMYYY,formatTime24hourFormat } from "../../util/helper";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading"
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Swal from "sweetalert2";

export default function OrderList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [data, setData] = useState([]);
  const [st, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(false);
const {companyId} = useCompany();


  useEffect(() => {
    setIsLoading(true);
    if(companyId > 0){
    getOrders();
    }
    setIsLoading(false);
  }, [companyId]);

  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };

  const getOrders = async () => {
    try {

      let obj={};
      if(!currentUser.isAdmin){
          obj={
            userId:currentUser.userId
          }
      }
      const res = await userRequest.post( `${companyId}/orders/find`,obj);

      const pedido = res.data.map((item) => ({
        orderId: item.orderId,
        orderRef: item.orderRef,
        amount: item.amount,
        status: item.status,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        quantity: item.quantity,
        uniqueId: item.uniqueId,
        name: item.customer.name,
        rzSocial: item.customer.rzSocial,
        cnpj_cpf: item.customer.cnpj_cpf,
        managedBy:item.managedBy,
      }));

      setData(pedido);
    } catch (error) {}
  };

  const nav = useNavigate();

  const AlterarStatus = async (obj) => {

    let st = "" ;
    if(obj.status !== "Finalizado"){
    // eslint-disable-next-line default-case
    switch (obj.status) {
      case "Pendente":
        st = "Confirmado";
        break;
      case "Confirmado":
        st = "Pago";
        break;
        case "Pago":
          st = "Preparado";
          break;
      case "Preparado":
        st = "Finalizado";
        break;
        case "Cancelado":
          st = "Cancelado";
          break;  
    }
    const res = await userRequest
      .put(`${companyId}/orders/${obj.orderId}`,{
        status: st,
        orderId: obj.orderId,
  
      })
    if(Object.keys(res.data).length > 0){
    setData((prevRows) => {

      return prevRows.map((row, index) =>
        row.orderId === obj.orderId ? { ...row, status: st } : row,
      );
    });
  }
  }
  };
  const onButtonDeleteClick = (e, row) => {
    if(currentUser.isAdmin){
      Swal.fire({
        title: 'Tem certeza que deseja Cancelar esse pedido?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#EE8253',
        confirmButtonText: 'Sim, canelar!',
        cancelButtonColor: '#d33',
        cancelButtonText: "Não"
      }).then(async (result) => {
        if (result.isConfirmed) {

          AlterarStatus({status:"Cancelado",orderId:row})
        }
      })
    }
    else{
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o produto!",
        "warning"
      );
    }
};

  const columns = [
    {
      field: "orderRef",
      headerName: "Número Pedido",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "cnpj_cpf",
      headerName: "CNPJ",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    { field: "rzSocial", headerName: "Razão Social", width: 200,headerClassName:'dataGridMuiHeader' },
    {
      field: "quantity",
      headerName: "Qtde Itens",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "amount",
      headerName: "Valor",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader'
    },
    { field: "createdAt", headerName: "Criado", width: 200, align: "center",headerAlign: "center",headerClassName:'dataGridMuiHeader',valueGetter: (value) => value && new Date(value),valueFormatter: (value) => {return `${formatTime24hourFormat(value)}`},type: "date" },
    { field: "updatedAt", headerName: "Atualizado", width: 200, align: "center",headerAlign: "center",headerClassName:'dataGridMuiHeader',valueGetter: (value) => value && new Date(value),valueFormatter: (value) => {return `${formatTime24hourFormat(value)}`},type: "date"},
    {
      field: "action",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return (
          <>
            <button 
              className={params.row.status === "Finalizado"? "button_custom_status_finalizado":"button_custom_status"}
              onClick={() => 
                AlterarStatus(params.row)
              }
            >
                {params.row.status === "Pendente" ? 
                "Confirmar Pedido" 
                : params.row.status === "Confirmado"
                ? "Pagar"
                : params.row.status === "Pago"
                  ? "Preparar"
                  : params.row.status=== "Preparado"
                    ? "Enviar"
                    : params.row.status}              
            </button>
          </>
        );
      },
    },
    {
      field: "itens",
      headerName: "Itens",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return (
          <>
            <Button variant="text" size="small"
              onClick={(e) => onButtonEditarClick(e, params.row.orderId)}
            >Detalhes
            </Button>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Cancelar Pedido",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        if(params.row.status  === "Pendente" || params.row.status  === "Confirmado"){
        return (
         
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.orderId)}
            variant="contained"
          >
            <HighlightOffIcon />
          </IconButton>
        );
        }
      },
    },    
  ];

  /* const rows=[
    {orderId: data.orderId, quantity:data.quantity,amount:data.amount,status:data.status}
  ];
*/

  // console.log("rows",rows)
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
 
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
          <div className="div_custom_UpdateLeft">
          <DataGridPremium 
              sx={{
              fontFamily:"Manrope",
              fontWeight:"500",
              border:"0px"
            }}
          autoHeight
      initialState={{
        rowGrouping: {
          model: ['status'],
        },
      }} 
        localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
        slots={{ toolbar: CustomToolbar }}
        rowHeight={30}
        getRowId={(row) => row.orderId}
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
      />
          </div>
        </div>
        </div>
      )}
    </div>
  );
}
