import "../../global.css";
import "./productList.css";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProducts } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { formatPricing } from "../../util/helper";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import { formatTimeDDMMYYY } from "../../util/helper";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import DiscountIcon from "@mui/icons-material/Discount";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export default function ProductList() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCompany();
  const [data, setData] = useState();

  useEffect(
    () => {
     
      if (companyId > 0) {
        getProducts();
      }

    },
    [companyId]
  );
  const getProducts = async () => {
    try {

      setIsLoading(true);
      const res = await userRequest.get(`${companyId}/products/admin`);
      setData(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const onButtonEditarClick = (e, row) => {
    nav(`${row}`);
  };

  const deletePrd = async (prd) => {
    const res = await userRequest.delete(`${companyId}/products/${prd}`);

    if (res.data > 0) {
      getProducts();
    } else {
      Swal.fire("Atenção!", "Erro ao excluir produto!", "error");
    }
  };

  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      Swal.fire({
        title: "Tem certeza que deseja excluir?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE8253",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          deletePrd(row);
        }
      });
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para excluir o produto!",
        "warning"
      );
    }
  };

  const columns = [
    {
      field: "enabled",
      headerName: "Visível",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        if (params.value) {
          return(
          <VisibilityOutlinedIcon fontSize="small" color="success"/>
          );
        } else {
          return (
          <VisibilityOffOutlinedIcon fontSize="small" sx={{ color: "red" }}/>
          );
        }
      },
    },
    {
      field: "status",
      headerName: "Status Produto",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      description:"S = todas numeração tem em estoque e tem foto, P = alguma numeração não tem em estoque e tem foto, N = não tem estoque ou não tem foto",
      renderCell: (params) => { 
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
            break;
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
            break;
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    { field: "ref", headerName: "Referência", width: 80, align: "center",headerClassName:'dataGridMuiHeader' },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 250,
      headerClassName:'dataGridMuiHeader'
    },
    {
      field: "category",
      headerName: "Categoria",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
    },
    {
      field: "price",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "discount",
      headerName: "Preço Cheio",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "promo",
      headerName: "Promocional",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return params.value ? <DiscountIcon color="success" /> : false;
      },
    },
    {
      field: "countImages",
      headerName: "Qtde de Fotos",
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',type: "number"
    },
    {
      field: "stock",
      headerName: "Estoque",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      description:"S = todas numeração tem em estoque, P = alguma numeração não tem em estoque, N = não tem estoque",
      renderCell: (params) => {
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
            break;
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
            break;
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "qtdeStock",
      headerName: "Qtde Produtod",
      width: 100,
      align: "center",
      headerAlign: "center",type: "number",headerClassName:'dataGridMuiHeader',
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),type: "date"
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      valueFormatter: (value) => { if(value===null){return ""}return `${formatTimeDDMMYYY(value)}`},
      valueGetter: (value) => value && new Date(value),type: "date"
    },
    {
      field: "action",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) => onButtonEditarClick(e, params.row.productId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName:'dataGridMuiHeader',
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => onButtonDeleteClick(e, params.row.productId)}
            variant="contained"
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ];

  const nav = useNavigate();
  const addRow = () => {
    nav(`new`);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateList">
          <div className="div_custom_UpdateRight">
          <button
              size="small"
              onClick={addRow}
              className="button_custom_action_add_top"
            >
              Adicionar Produto
            </button>
          </div>
          <div className="div_custom_UpdateLeft">
          {data ? 
            <DataGridPremium
              autoHeight
              sx={{
                fontFamily:"Manrope",
                fontWeight:"500",
                border:"0px"
              }}
              rowHeight={30}  
              localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
              getRowId={(row) => row.productId}
              rows={data}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
            />:false}
          </div>
        </div>
        </div>
      )}
    </div>
  );
}
