import { useEffect, useState } from "react";
import { publicRequest, userRequest } from "../../requestMethods";
import { Link } from "react-router-dom";
import "../../global.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading"
import { useCompany } from "../../components/context/CompanyContext";

export default function User() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [user, setUser] = useState({});
  const [inputs, setInputs] = useState({});
  const [status, setStatus] = useState({});
  const [cargo, setCargo] = useState("");
  const [admin, setAdmin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
const {companyId} = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);

  const nav = useNavigate();

  useEffect(() => {

    if (id !== "new" && companyId > 0) {
      getUser();
    }
  }, [id,companyId]);

  useEffect(() => {
    if (user) {
      if(user.position){
        setCargo(user.position)
      }
        setAdmin(user.isAdmin)
    }
  }, [user]);

  
  const getUser = async () => {
    try {
      const res = await userRequest.get(`${companyId}/users/find/${id}`);

      res.data.map((item) => {
        
        setUser(item);
      });
    } catch {
      console.log();
    }
  };
  const validate = () => {
    if (inputs.username === "" || inputs.username === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Username deve ser preenchido.",
        "warning"
      );
      return false;
    }
    if (inputs.password === "" || inputs.password === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Senha deve ser preenchido.",
        "warning"
      );
      return false;
    }
    if (inputs.name === "" || inputs.name === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Nome deve ser preenchido.",
        "warning"
      );

      return false;
    }
    if (inputs.cpf === "" || inputs.cpf === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo CPF deve ser preenchido.",
        "warning"
      );

      return false;
    }
    if (inputs.email === "" || inputs.email === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Email deve ser preenchido.",
        "warning"
      );

      return false;
    }

    if (inputs.contact === "" || inputs.contact === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Telefone deve ser preenchido.",
        "warning"
      );

      return false;
    }
    if (inputs.address === "" || inputs.address === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Endereço deve ser preenchido.",
        "warning"
      );

      return false;
    }
    if (!cargo) {
      Swal.fire(
        "Atenção!",
        "O campo Cargo deve ser selecionado.",
        "warning"
      );

      return false;
    }
    if (!admin) {
      Swal.fire(
        "Atenção!",
        "O campo Administrador deve ser selecionado.",
        "warning"
      );

      return false;
    }


    return true;
  };
  const updateClick = async (event) => {

    setIsLoading(true);
    if (id !== "new") {
      UpdateUser();
    } else {
      if (validate()) {
        InserirUser();
      }
    }
    setIsLoading(false);
   
  };
  const UpdateUser = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/users/${id}`, {
        userId: currentUser.userId,
        name: inputs.name,
        email: inputs.email,
        contact: inputs.contact,
        position: cargo,
        username: inputs.username,
        password: inputs.password,
        cpf: inputs.cpf,
        isAdmin: admin,
        address: inputs.address,
        password: inputs.password,
      });

      if(Object.keys(res.data).length > 0){

        Swal.fire("Parabéns!", "Usuário alterado com sucesso!", "success");
        nav(`../users`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar usuário!", "error");
      }
    } catch (error) {}
  };
  const InserirUser = async () => {
    try {
      const res = await userRequest.post(`${companyId}/users/registerEmployee`, [
        {
          name: inputs.name,
          email: inputs.email,
          contact: inputs.contact,
          position: cargo,
          username: inputs.username,
          password: inputs.password,
          cpf: inputs.cpf,
          isAdmin: admin,
          address: inputs.address,
        },
      ]);


      if(Object.keys(res.data).length > 0){

        Swal.fire("Parabéns!", "Usuário inserido com sucesso!", "success");
         nav(`../users`);
      } else {
        Swal.fire("Atenção!", "Erro ao inserir usuário!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      console.log([e.target.name], e.target.value);
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangePosition = (e) => {
    setCargo(e.target.value);
  };
  const handleChangeAdmin = (e) => {
    setAdmin(e.target.value)
  };
  return (
    <div className="div_custom_">
      {isLoading ? <Loading /> : 
      <div className="div_custom_Container">
        <div className="div_custom_Update">
          {id !== "new" && user  ? (
            <span className="div_custom_UpdateTitle">Editar Usuário</span>
          ) : (
            <span className="div_custom_UpdateTitle">Novo Usuário</span>
          )}
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Username</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="username"
                defaultValue={id !== "new" && user ? user.username || "" : ""}
                onChange={handleChange}
                maxLength="20"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Senha</label>
              <input
                type="text"
                name="password"
                defaultValue={id !== "new" && user  ? "" || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="20"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Nome</label>
              <input
                type="text"
                name="name"
                defaultValue={id !== "new" && user  ? user.name || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>CPF</label>
              <input
                type="number"
                name="cpf"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user  ? user.cpf || "" : ""}
                onChange={handleChange}
                maxLength="11"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user  ? user.email || "" : ""}
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Telefone</label>
              <input
                type="number"
                name="contact"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user  ? user.contact || "" : ""}
                maxLength="12"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Endereço</label>
              <input
                type="text"
                name="address"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" && user  ? user.address || "" : ""}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Cargo</label>
              <select
                className="div_custom_Select"
                name="position"
                id="position"
                onChange={handleChangePosition}
                value={cargo}
              >
                <option value=""></option>
                <option value="Gerente">Gerente</option>
                <option value="Vendedor(a)">Vendedor(a)</option>
                <option value="Estoquista">Estoquista</option>
              </select>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Administrador</label>
              <select
                className="div_custom_Select"
                name="isAdmin"
                id="isAdmin"
                onChange={handleChangeAdmin}
                value={admin}
              >
                <option value=""></option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
          </div>
          <div className="div_custom_UpdateRight">
            {id !== "new" && user  ? (
              <button className="button_custom_action" onClick={updateClick}>
                Alterar
              </button>
            ) : (
              <button className="button_custom_action" onClick={updateClick}>
                Cadastrar
              </button>
            )}
          </div>
        </div>
      </div>
}
    </div>
  );
}
