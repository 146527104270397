import "../../global.css";
import "./productList.css";
import "./importProducts.css";
import "../config/paleta";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/apiCalls";
import { useState } from "react";
import * as XLSX from "xlsx";
import { userRequest } from "../../requestMethods";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { formatPricing } from "../../util/helper";
import Loading from "../../components/loading/loading"
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import { useCompany } from "../../components/context/CompanyContext";
import { generateFileUrl } from "../../requestMethods";

export default function ImportProducts() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [dataXLS, setDataXLS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {companyId} = useCompany();

  useEffect(() => {
    getProducts(dispatch,true,companyId);
  }, [dispatch,companyId]);

  useEffect(() => {

  }, [dataXLS]);

  const columns = [
    {
      field: "productId",
      headerName: "ID Produto",
      width: 1,
      align: "left",
      hideable: false,
      headerClassName:'dataGridMuiHeader', 
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      width: 200,
      headerClassName:'dataGridMuiHeader', 
    },
    { field: "ref", headerName: "Referência",headerClassName:'dataGridMuiHeader',  width: 200, align: "left" },
    {
      field: "shortDesc",
      headerName: "Descrição Curta",
      width: 200,
      align: "left",
      headerClassName:'dataGridMuiHeader', 
    },
    { field: "desc", headerName: "Descrição",headerClassName:'dataGridMuiHeader',  width: 200, align: "left" },
    {
      field: "price",
      headerName: "Preço",
      width: 160,
      align: "left",
      headerClassName:'dataGridMuiHeader', 
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "discount",
      headerName: "Preço Cheio",
      width: 200,
      align: "left",
      headerClassName:'dataGridMuiHeader', 
      valueFormatter: (params) => formatPricing(params?.value),
    },
    { field: "colors", headerName: "Cores", width: 200,headerClassName:'dataGridMuiHeader',  align: "left" },
    { field: "sizes", headerName: "Tamanhos", headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    { field: "category", headerName: "Categoria", headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    {
      field: "subCategory",
      headerName: "Subcategoria",
      width: 200,
      align: "left",
      headerClassName:'dataGridMuiHeader', 
    },
  ];

  const addXLS = async () => {
    setIsLoading(true);
    if (dataXLS.length > 0) {
      const product = Array.from(dataXLS).map((item) => ({
        title: item.title,
        ref: item.ref.toString(),
        shortDesc: item.shortDesc,
        desc: item.desc,
        price: item.price,
        discount: item.discount,
        colors: item.colors ? item.colors.split(","):["Não informada"],
        sizes: item.sizes.split(","),
        category: item.subCategory ? item.subCategory : item.category,
      }));

      
      const res = await userRequest.post(`${companyId}/products/import`, product);


      if(Object.keys(res.data).length > 0){
      Swal.fire("Parabéns!", "Produtos adicionado com sucesso!", "success");
      }
    } else {
      Swal.fire("Atenção!", "Favor selecionar um aquivo!", "warning");
    }
    setIsLoading(false);
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      if (validaFile(workbook)) {
        const index = workbook.SheetNames.findIndex(
          (item) => item === "Produtos"
        );
        const sheetName = workbook.SheetNames[index];
        const sheet = workbook.Sheets[sheetName];

        sheet["A1"].w = "title";
        sheet["B1"].w = "ref";
        sheet["C1"].w = "shortDesc";
        sheet["D1"].w = "desc";
        sheet["E1"].w = "price";
        sheet["F1"].w = "discount";
        sheet["G1"].w = "category";
        sheet["H1"].w = "subCategory";
        sheet["I1"].w = "RN";
        sheet["J1"].w = "PP";
        sheet["K1"].w = "P";
        sheet["L1"].w = "M";
        sheet["M1"].w = "G";
        sheet["N1"].w = "N34";
        sheet["O1"].w = "N36";
        sheet["P1"].w = "N38";
        sheet["Q1"].w = "N40";
        sheet["R1"].w = "N42";
        sheet["S1"].w = "N44";
        sheet["T1"].w = "N46";
        sheet["U1"].w = "N48";
        sheet["V1"].w = "Amarelo";
        sheet["W1"].w = "Azul";
        sheet["X1"].w = "AzulMarinho";
        sheet["Y1"].w = "Marron";
        sheet["Z1"].w = "Branco";
        sheet["AA1"].w = "Bege";
        sheet["AB1"].w = "Cinza";
        sheet["AC1"].w = "Terracota";
        sheet["AD1"].w = "Laranja";
        sheet["AE1"].w = "Vinho";
        sheet["AF1"].w = "Roxo";
        sheet["AG1"].w = "Preto";
        sheet["AH1"].w = "Rosa";
        sheet["AI1"].w = "Verde";
        sheet["AJ1"].w = "VerdeEscuro";
        sheet["AK1"].w = "Vermelho";

        const parsedData = XLSX.utils.sheet_to_json(sheet,{raw: false});

        const newParseData = parsedData.map((item) => ({
          title: item.title,
          ref: item.ref.replace(",","."),
          shortDesc: item.shortDesc,
          desc: item.desc,
          price: item.price,
          discount: item.discount,
          category: item.category,
          subCategory: item.subCategory,
          sizes:
            (item.RN ? "RN," : "") +
            (item.PP ? "PP," : "") +
            (item.P ? "P," : "") +
            (item.M ? "M," : "") +
            (item.G ? "G," : "") +
            (item.N34 ? "34," : "") +
            (item.N36 ? "36," : "") +
            (item.N38 ? "38," : "") +
            (item.N40 ? "40," : "") +
            (item.N42 ? "42," : "") +
            (item.N44 ? "44," : "") +
            (item.N46 ? "46," : "") +
            (item.N48 ? "48" : ""),
            colors:
          (item.Amarelo ? "Amarelo," : "") +	
          (item.Azul ? "Azul," : "") +	
          (item.AzulMarinho ? "Azul Marinho," : "") +	
          (item.Marron ? "Marron," : "") +	
          (item.Branco ? "Branco," : "") +	
          (item.Bege ? "Bege," : "") +	
          (item.Cinza ? "Cinza," : "") +	
          (item.Terracota ? "Terracota," : "") +	
          (item.Laranja ? "Laranja," : "") +	
          (item.Vinho ? "Vinho," : "") +	
          (item.Roxo ? "Roxo," : "") +	
          (item.Preto ? "Preto," : "") +	
          (item.Rosa ? "Rosa," : "") +	
          (item.Verde ? "Verde," : "") +	
          (item.VerdeEscuro ? "Verde Escuro," : "") +	
          (item.Vermelho ? "Vermelho" : "") 
        }));
        setDataXLS(newParseData);
      } else {
        Swal.fire("Erro!", "Estrutura do arquivo modificada.", "warning");
      }
    };
  };

  const validaFile = (workbook) => {
    const index = workbook.SheetNames.findIndex((item) => item === "Produtos");
    const sheetName = workbook.SheetNames[index];
    const sheet = workbook.Sheets[sheetName];


    if (index === -1) {
      return false;
    }
    if (sheet["A1"].v !== "Produto") {
      return false;
    }
    if (sheet["B1"].v !== "Referencia") {
      return false;
    }
    if (sheet["C1"].v !== "Breve Descrição") {
      return false;
    }
    if (sheet["D1"].v !== "Descrição") {
      return false;
    }
    if (sheet["E1"].v !== "Preço") {
      return false;
    }
    if (sheet["F1"].v !== "Preço Cheio") {
      return false;
    }
    if (sheet["G1"].v !== "Categoria") {
      return false;
    }
    if (sheet["H1"].v !== "Subcategoria") {
      return false;
    }
    if (sheet["I1"].v !== "RN") {
      return false;
    }
    if (sheet["J1"].v !== "PP") {
      return false;
    }
    if (sheet["K1"].v !== "P") {
      return false;
    }
    if (sheet["L1"].v !== "M") {
      return false;
    }
    if (sheet["M1"].v !== "G") {
      return false;
    }

    if (sheet["N1"].v.toString() !== "34") {
      return false;
    }
    if (sheet["O1"].v.toString() !== "36") {
      return false;
    }
    if (sheet["P1"].v.toString() !== "38") {
      return false;
    }
    if (sheet["Q1"].v.toString() !== "40") {
      return false;
    }
    if (sheet["R1"].v.toString() !== "42") {
      return false;
    }
    if (sheet["S1"].v.toString() !== "44") {
      return false;
    }
    if (sheet["T1"].v.toString() !== "46") {
      return false;
    }
    if (sheet["U1"].v.toString() !== "48") {
      return false;
    }
    if (sheet["V1"].v !== "Amarelo") {
      return false;
    }
    if (sheet["W1"].v !== "Azul") {
      return false;
    }
    if (sheet["X1"].v !== "Azul Marinho") {
      return false;
    }
    if (sheet["Y1"].v !== "Marron") {
      return false;
    }
    if (sheet["Z1"].v !== "Branco") {
      return false;
    }
    if (sheet["AA1"].v !== "Bege") {
      return false;
    }
    if (sheet["AB1"].v !== "Cinza") {
      return false;
    }
    if (sheet["AC1"].v !== "Terracota") {
      return false;
    }
    if (sheet["AD1"].v !== "Laranja") {
      return false;
    }
    if (sheet["AE1"].v !== "Vinho") {
      return false;
    }
    if (sheet["AF1"].v !== "Roxo") {
      return false;
    }
    if (sheet["AG1"].v !== "Preto") {
      return false;
    }
    if (sheet["AH1"].v !== "Rosa") {
      return false;
    }
    if (sheet["AI1"].v !== "Verde") {
      return false;
    }
    if (sheet["AJ1"].v !== "Verde Escuro") {
      return false;
    }
    if (sheet["AK1"].v !== "Vermelho") {
      return false;
    }

    return true;
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  let downalodFIle = generateFileUrl("ImportFiles/ImportarProdutos.xlsx");
  return (
    <div className="div_custom_">
      {isLoading ? <Loading /> :
        <div className="div_custom_Container">
        <div className="div_custom_UpdateList">
      <div>
          <input
            id="selecao-arquivo"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload} />
        </div><div>
            <button
              size="small"
              onClick={addXLS}
              className="button_custom_action_add_top"
            >
              Importar Excel
            </button>
          </div><div>
            <labe>Informações para importação de produtos</labe>
            <br></br>
          </div><div className="custom_label">
            <labe>
              1. O arquivo deve ser utilizado na mesma estrutura de download.
              <a href={downalodFIle} download>
                Download Arquivo
              </a>
            </labe>
          </div><div className="custom_label">
            <labe>
              2. As numerações e cores informadas no arquivo deverão ser da grade e
              cores selecionados nas configurações de{" "}
              <a href={`colorsize`}>tamanhos e cores</a>.Caso a numerão não exista,
              favor falar com nosso suporte.
            </labe>
          </div><div>
            <br></br>
            <DataGridPremium
              autoHeight
              sx={{
                fontFamily:"Manrope",
                fontWeight:"500",
                border:"0px"
              }}
              rowHeight={30} 
              localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
              getRowId={(row) => row.title + row.ref}
              rows={dataXLS}
              disableSelectionOnClick
              columns={columns}
              pageSize={8} />
          </div>
          </div>
          </div>
}
    </div>
  );
}
