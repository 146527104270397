import { useEffect, useState, useRef } from "react";
import { generateFileUrl, userRequest } from "../../requestMethods";
import "../../global.css";
import "./highlights.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { formatPricing } from "../../util/helper";
import { ptBR as ptBRCore } from '@mui/x-data-grid/locales';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { getProducts } from "../../redux/apiCalls";
import { useCompany } from "../../components/context/CompanyContext";

export default function Highlights() {
  const location = useLocation();
  const [home, setHome] = useState({});
  const [files, setFiles] = useState({});
  const [inputs, setInputs] = useState({});
  const [prdsTitle1, setprdsTitle1] = useState([{}]);
  const [prdsTitle2, setprdsTitle2] = useState([{}]);
  const [filesIni, setFilesIni] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [checked, setChecked] = useState(true);
  const inputTitle1 = useRef("");
  const inputSubtitle1 = useRef("");
  const inputTitle2 = useRef("");
  const inputSubtitle2 = useRef("");
  const inputRef = useRef();
  const [highs, setHighs] = useState([{}]);
  const nav = useNavigate();
const {companyId} = useCompany();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    getHome();
    getProducts(dispatch,false,companyId);
  }, [dispatch,companyId]);


  useEffect(() => {
    if (home && Object.keys(home).length > 0) {
  
      setChecked(home.allproducts);
      setFiles(home.banners);


      if (home.banners && home.banners.length > 0) {
        setFilesIni(true);
      } else {
        setFilesIni(false);
      }

      selectGrid1();
      selectGrid2();
    }
  }, [home]);

  useEffect(() => {
 
  }, [highs]);

  const [selectionModel1, setSelectionModel1] = useState();
  const [selectionModel2, setSelectionModel2] = useState();

  const selectGrid1 = () => {
    if(home.hightlights){
    setprdsTitle1(home.hightlights[0].products);
    if (home.hightlights[0].products && home.hightlights[0].products !== undefined)
      setSelectionModel1(home.hightlights[0].products);
    }
  };
  const selectGrid2 = () => {
    if(home.hightlights){
    if (home.hightlights.length > 1) {
      setprdsTitle2(home.hightlights[1].products);
      setSelectionModel2(home.hightlights[1].products);
    }
  }
  };
  const getHome = async () => {
    try {
      const res = await userRequest.get(`${companyId}/home/admin`);
      
      if (Object.keys(res.data).length > 0){
      setHome(res.data);
      }
    } catch {
      console.log();
    }
  };
  const validadeListProduct = () => {
 
    if (prdsTitle1.length < 3 || prdsTitle1.length > 35) {
      Swal.fire(
        "Atenção!",
        "A lista de produtos 1 deve ter no mínimo 3 e máximo 35 produtos.",
        "warning"
      );

      return false;
    } 
    return true;
  };
  const validadeListProduct2 = () => {
    if (!validateTitle2()) {
      if (prdsTitle2.length < 3 || prdsTitle2.length > 35) {
                Swal.fire(
          "Atenção!",
          "A lista de produtos 2 deve ter no mínimo 3 e maáximo 35 produtos.",
          "warning"
        );
        return false;
      } 
    }
    return true;
  };
  const validate = () => {
    
    if (
      inputTitle1.current.value === "" ||
      inputTitle1.current.value === undefined
    ) {
      return false;
    }
    if (inputSubtitle1.current.value === "" || inputSubtitle1.current.value === undefined) {
      return false;
    }
    if (!validateTitle2()) {
      return false;
    }
    return true;
  };
  const validateTitle2 = () => {

    if (inputSubtitle2.current.value !== "" && (inputTitle2.current.value === "" || inputTitle2.current.value === undefined)) {
      return false;
    }
    return true;
  };

  const cadastrarClick = async (event) => {
    let upload = true;
    if (!filesIni) {
      upload = handleUpload();
    }

    if (upload) {
      
      if (Object.keys(home).length > 0) {
    
        if (validadeListProduct()) {
          if(validadeListProduct2()){
          Update();
          }
        }
      } else {
       
        if (validate()) {
          if (validadeListProduct()) {
            if(validadeListProduct2()){
            Inserir();
            }
          }
        } else {
          Swal.fire(
            "Atenção!",
            "Os campos Título, Subtítulo e a lista produtos devem ser preenchidos",
            "warning"
          );
        }
      }
    }
  };
  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/home/`, {
        allproducts: checked,
        hightlights: objHilights(),
      });
      if(Object.keys(res.data).length > 0){
        Swal.fire("Parabéns!", "Home do app alterado com sucesso!", "success");
      } else {
        Swal.fire("Atenção!", "Erro ao alterar a home!", "error");
      }
    } catch (error) {
      console.log("Update", error);
    }
  };

  const objHilights = () => {
    let arrayHighlights = [];
    arrayHighlights.push({
      title: inputTitle1.current.value,
      subTitle: inputSubtitle1.current.value,
      products: prdsTitle1,
      sort:0
    });

    if (inputTitle2.current.value !== "") {
      arrayHighlights.push({
        title: inputTitle2.current.value,
        subTitle: inputSubtitle2.current.value,
        products: prdsTitle2,
        sort:1
      });
    }

    return arrayHighlights;
  };
  const Inserir = async () => {
    try {
      const res = await userRequest.post(`${companyId}/home/`, {
        allproducts: checked,
        hightlights: objHilights(),
      });
      if(Object.keys(res.data).length > 0){
        Swal.fire("Parabéns!", "Home do app inserida com sucesso!", "success");
      } else {
        Swal.fire("Atenção!", "Erro ao inserir a home do app!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpload = async (event) => {
    const formData = new FormData();

    if(files){
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        const photo = files[i];
        formData.append("image", photo);
      }
    }
    const res = userRequest.post(`${companyId}/products/images/banners`, formData);
  

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleOnDelete = (event) => {
    setFilesIni(false);
    setFiles({});

    const res = userRequest.delete(`${companyId}/products/images/deletebanners`);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChange = (event) => {
    setFiles(event.target.files);
    
  };
  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };
  const columns = [
    {
      field: "productId",
      headerName: "ID Produto",
      headerClassName:'dataGridMuiHeader',
      width: 200,
      align: "center",
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      headerClassName:'dataGridMuiHeader',
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {params.row.title}
          </div>
        );
      },
    },
    { field: "ref", headerName: "Referência", width: 200,headerClassName:'dataGridMuiHeader', align: "center" },
    { field: "category", headerName: "Categoria",headerClassName:'dataGridMuiHeader', width: 200, align: "left" },
    {
      field: "price",
      headerName: "Preço",
      width: 160,
      headerClassName:'dataGridMuiHeader',
      align: "left",
      valueFormatter: (params) => formatPricing(params?.value),
    },
    {
      field: "discount",
      headerName: "Desconto",
      width: 200,
      headerClassName:'dataGridMuiHeader',
      align: "left",
      valueFormatter: (params) => formatPricing(params?.value),
    },
  ];
  const handleChangeChecked = (e) => {
    setChecked(e.target.checked);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="div_custom_">
      <div className="div_custom_Container">
      <div className="div_custom_UpdateList">
          <div className="div_custom_TitleContainer">
            <span className="div_custom_UpdateTitle">Home App</span>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Banners</label>
          </div>
          <div>
            {files && files.length > 0 ? (
              <div className="uploads">
                {filesIni
                  ? Array.from(files).map((item, index) => (
                      <img
                        className="bannerUpload"
                        src={generateFileUrl(item)}
                      />
                    ))
                  : Array.from(files).map((item, index) => (
                      <img
                        className="bannerUpload"
                        src={URL.createObjectURL(item)}
                      />
                    ))}
                <div>
                  <button
                    className="button_custom_import"
                    onClick={handleOnDelete}
                  >
                    Excluir Banners
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  multiple
                  onChange={handleOnChange}
                  hidden
                  accept="image/png, image/jpeg"
                  ref={inputRef}
                />
                <button
                  onClick={handleOnclick}
                  className="button_custom_import"
                >
                  Selecione os banners
                </button>
              </div>
            )}
          </div>
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Título</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="title1"
                defaultValue={home && home.hightlights ? home.hightlights[0].title : ""}
                onChange={handleChange}
                maxlength="60"
                ref={inputTitle1}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Subtítulo</label>
              <input
                type="text"
                name="substitle1"
                defaultValue={home  && home.hightlights  ? home.hightlights[0].subTitle : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                ref={inputSubtitle1}
                maxlength="60"
              />
            </div>
            <div className="div_custom_UpdateLeft">
              <DataGridPremium
                          sx={{
                            fontFamily:"Manrope",
                            fontWeight:"500",
                            border:"0px"
                          }}
                          rowHeight={30} 
                localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                getRowId={(row) => row.productId}
                rows={products}
                rowSelectionModel={selectionModel1}
                onRowSelectionModelChange={(itm) => {
                  setSelectionModel1(itm);
                  /*const selectedIDs = new Set(itm);
                    const select = products.filter((row) =>
                    selectedIDs.has(row.productId)
                  );
                  */
                  setprdsTitle1(itm);
                }}
                columns={columns}
                pageSize={8}
                checkboxSelection
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Título 2</label>
              <input
                type="text"
                className="div_custom_UpdateInput"
                name="title2"
                defaultValue={
                  home && home.hightlights && home.hightlights.length > 1
                    ? home.hightlights[1].title
                    : ""
                }
                onChange={handleChange}
                ref={inputTitle2}
                maxlength="60"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Subtítulo 2 </label>
              <input
                type="text"
                name="substitle2"
                defaultValue={
                  home && home.hightlights  && home.hightlights.length > 1
                    ? home.hightlights[1].subTitle
                    : ""
                }
                className="div_custom_UpdateInput"
                onChange={handleChange}
                ref={inputSubtitle2}
                maxlength="60"
              />
            </div>
            <div className="div_custom_UpdateLeft">
              <DataGridPremium
                          sx={{
                            fontFamily:"Manrope",
                            fontWeight:"500",
                            border:"0px"
                          }}
                          rowHeight={30} 
                localeText={ptBRCore.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                getRowId={(row) => row.productId}
                rows={products}
                rowSelectionModel={selectionModel2}
                onRowSelectionModelChange={(itm) => {
                  setSelectionModel2(itm);
                  setprdsTitle2(itm);
                }}
                columns={columns}
                pageSize={8}
                checkboxSelection
              />
            </div>
            <div>
              <input
                className="custom_Checkbox"
                type="checkbox"
                name="checkAllProducts"
                onChange={handleChangeChecked}
                checked={checked}
              />
              <label>Deseja ver todos os produtos na Home do app?</label>
            </div>
          </div>
          <div>
            <button className="button_custom_action" onClick={cadastrarClick}>
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

